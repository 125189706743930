import { createEffect } from 'effector'
import api from './axiosClient'

export const getInfo = createEffect(async ({ url }: { url: string }) => {
  const { data } = await api.get(url)

  return { data }
})

export const updateMood = createEffect(
  async ({ url, mood, id }: { url: string; mood: string; id: number }) => {
    const { data } = await api.post(url, { mood, id })

    return { data }
  },
)

export const updateStatus = createEffect(
  async ({ url, status, id }: { url: string; status: string; id: number }) => {
    const { data } = await api.post(url, { status, id })

    return { data }
  },
)

export const updateName = createEffect(
  async ({ url, name, id }: { url: string; name: string; id: number }) => {
    const { data } = await api.post(url, { name, id })

    return { data }
  },
)
