export default function BackIcon() {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.29 6.85573H5.84413L9.78839 2.9325C10.1113 2.60989 10.2927 2.17235 10.2927 1.71612C10.2927 1.25989 10.1113 0.822351 9.78839 0.499748C9.46547 0.177146 9.02749 -0.00408936 8.57081 -0.00408936C8.11413 -0.00408936 7.67616 0.177146 7.35324 0.499748L0.493652 7.35256C0.337526 7.51549 0.215143 7.70762 0.133523 7.91791C-0.0379973 8.33501 -0.0379973 8.80285 0.133523 9.21995C0.215143 9.43024 0.337526 9.62237 0.493652 9.7853L7.35324 16.6381C7.51266 16.7987 7.70233 16.9261 7.91131 17.0131C8.12028 17.1001 8.34443 17.1449 8.57081 17.1449C8.7972 17.1449 9.02135 17.1001 9.23032 17.0131C9.4393 16.9261 9.62897 16.7987 9.78839 16.6381C9.94913 16.4788 10.0767 16.2894 10.1638 16.0806C10.2508 15.8718 10.2957 15.6479 10.2957 15.4217C10.2957 15.1956 10.2508 14.9717 10.1638 14.7629C10.0767 14.5541 9.94913 14.3646 9.78839 14.2054L5.84413 10.2821H22.29C22.7448 10.2821 23.181 10.1016 23.5026 9.78035C23.8242 9.45906 24.0049 9.0233 24.0049 8.56893C24.0049 8.11456 23.8242 7.6788 23.5026 7.35751C23.181 7.03623 22.7448 6.85573 22.29 6.85573Z"
        fill="black"
      />
    </svg>
  )
}
