import { toast } from 'react-toastify'
import styles from './AuthModule.module.scss'
import flowerImage1 from './images/flower1.png'
import flowerImage2 from './images/flower2.png'
import { login } from '../../../api/auth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setUser } from '../../../store/user'

export default function AuthModule() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const onLogin = async () => {
    try {
      const { data } = await login({
        url: '/auth/login',
        username: username,
        password: password,
      })

      localStorage.setItem('token', data.token)
      setUser(data.user)

      toast.success('Авторизация прошла успешно')

      window.location.reload()

      navigate('/dashboard/cards')
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка')
    }
  }

  return (
    <div className={styles.AuthModule}>
      <div className={styles.Decoration}>
        <img src={flowerImage1} alt="Цветок" draggable={false} />
        <img src={flowerImage2} alt="Цветок" draggable={false} />
      </div>
      <input
        className={styles.Input}
        type="text"
        placeholder="Логин"
        onChange={e => setUsername(e.target.value)}
        required
      />
      <input
        className={styles.Input}
        type="password"
        placeholder="Пароль"
        onChange={e => setPassword(e.target.value)}
        required
      />
      <button className={styles.Button} onClick={onLogin}>
        Войти
      </button>
    </div>
  )
}
