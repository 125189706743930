import Background from '../../components/elements/Background'
import Layout from '../../components/layout/Layout'
import ProfileSettings from '../../components/modules/ProfileSettings'

export default function ProfilePage() {
  return (
    <>
      <Background />
      <Layout>
        <ProfileSettings />
      </Layout>
    </>
  )
}
