// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageList_MessageList__\\+9Q2E {

    display: flex;

    flex: 1 1;

    flex-direction: column;

    gap: 10px;

    overflow: auto;

    padding: 20px
}

.MessageList_DateLabel__VUQ1L {

    margin-top: 5px;

    margin-bottom: 5px;

    margin-left: auto;

    margin-right: auto;

    width: fit-content;

    cursor: default;

    -webkit-user-select: none;

            user-select: none;

    border-radius: 12px;

    background-color: rgb(0 0 0 / 0.2);

    padding-left: 10px;

    padding-right: 10px;

    padding-top: 5px;

    padding-bottom: 5px;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/components/modules/MessageList/MessageList.module.scss"],"names":[],"mappings":"AACE;;IAAA,aAAA;;IAAA,SAAA;;IAAA,sBAAA;;IAAA,SAAA;;IAAA,cAAA;;IAAA;AAAA;;AAIA;;IAAA,eAAA;;IAAA,kBAAA;;IAAA,iBAAA;;IAAA,kBAAA;;IAAA,kBAAA;;IAAA,eAAA;;IAAA,yBAAA;;YAAA,iBAAA;;IAAA,mBAAA;;IAAA,kCAAA;;IAAA,kBAAA;;IAAA,mBAAA;;IAAA,gBAAA;;IAAA,mBAAA;;IAAA,oBAAA;;IAAA;AAAA","sourcesContent":[".MessageList {\n  @apply flex-1 flex-col gap-[10px] overflow-auto p-[20px] flex;\n}\n\n.DateLabel {\n  @apply w-fit cursor-default select-none rounded-[12px] bg-black/20 px-[10px] py-[5px] text-white my-[5px] mx-auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MessageList": `MessageList_MessageList__+9Q2E`,
	"DateLabel": `MessageList_DateLabel__VUQ1L`
};
export default ___CSS_LOADER_EXPORT___;
