import { useEffect, useState } from 'react'
import Backdrop from '../../../elements/Backdrop'
import styles from '../styles/Modal.module.scss'
import SendiImage from '../images/background-sendi.webp'
import SendiSadImage from '../images/background-sendi-sad.png'

export default function MessageModal({ message }: { message: string }) {
  const [show, setShow] = useState(true)
  const [mode, setMode] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)

    if (message.includes('Упс,')) {
      setMode('sad')
    }
  }, [message])

  return show ? (
    <>
      <Backdrop />
      <div className={styles.Modal}>
        <img
          className={`${styles.BackgroundImage} ${mode === 'sad' ? styles.SadImage : ''}`}
          src={mode === 'sad' ? SendiSadImage : SendiImage}
          alt="Сэнди Чикс"
          draggable={false}
        />
        <h3 className={styles.Message}>{message}</h3>

        <button className={styles.NextButton} onClick={() => setShow(false)}>
          Далее
        </button>
      </div>
    </>
  ) : (
    <></>
  )
}
