export default function DownloadIcon() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C6.73478 0 6.48043 0.105357 6.29289 0.292893C6.10536 0.48043 6 0.734784 6 1V10.529L1.782 6.306C1.68513 6.209 1.57009 6.13205 1.44346 6.07955C1.31683 6.02705 1.18108 6.00002 1.044 6.00002C0.906915 6.00002 0.771175 6.02705 0.644543 6.07955C0.51791 6.13205 0.40287 6.209 0.306 6.306C0.110225 6.50211 0.000267029 6.76789 0.000267029 7.045C0.000267029 7.32211 0.110225 7.58789 0.306 7.784L6.21 13.694C6.427 13.911 6.716 14.013 7 13.999C7.284 14.013 7.573 13.911 7.79 13.694L13.694 7.784C13.8898 7.58789 13.9997 7.32211 13.9997 7.045C13.9997 6.76789 13.8898 6.50211 13.694 6.306C13.5971 6.209 13.4821 6.13205 13.3555 6.07955C13.2288 6.02705 13.0931 6.00002 12.956 6.00002C12.8189 6.00002 12.6832 6.02705 12.5565 6.07955C12.4299 6.13205 12.3149 6.209 12.218 6.306L8 10.529V1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0ZM0 17C0 16.7348 0.105357 16.4804 0.292893 16.2929C0.48043 16.1054 0.734784 16 1 16H13C13.2652 16 13.5196 16.1054 13.7071 16.2929C13.8946 16.4804 14 16.7348 14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17Z"
        fill="gray"
      />
    </svg>
  )
}
