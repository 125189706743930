// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardStatistics_CardStatistics__G0cJE {
  position: relative;
  bottom: 45px;
  left: 50%;
  display: flex;
  width: fit-content;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  padding: 12px
}
@media (max-width: 600px) {
  .CardStatistics_CardStatistics__G0cJE {
    flex-direction: column
  }
}

.CardStatistics_Part__jbNIs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 127 80 / var(--tw-bg-opacity));
  padding: 8px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/components/modules/CardStatistics/CardStatistics.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAAA,YAAA;EAAA,SAAA;EAAA,aAAA;EAAA,kBAAA;EAAA,sBAAA;EAAA,+LAAA;EAAA,mBAAA;EAAA,SAAA;EAAA,mBAAA;EAAA,kBAAA;EAAA,sDAAA;EAAA;AAAA;AAEA;EACE;IAAA;EAAA;AAGJ;;AAEE;EAAA,aAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,kBAAA;EAAA,kBAAA;EAAA,wDAAA;EAAA,YAAA;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".CardStatistics {\n  @apply bottom-[45px] left-2/4 flex w-fit -translate-x-2/4 items-center gap-[20px] rounded-[12px] bg-gray-600 p-[12px] relative;\n\n  @media (max-width: 600px) {\n    @apply flex-col;\n  }\n}\n\n.Part {\n  @apply flex flex-col items-center justify-center rounded-[8px] bg-coral p-[8px] text-white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardStatistics": `CardStatistics_CardStatistics__G0cJE`,
	"Part": `CardStatistics_Part__jbNIs`
};
export default ___CSS_LOADER_EXPORT___;
