import { createEffect } from 'effector'
import { toast } from 'react-toastify'
import api from './axiosClient'

export const getCardStatistics = createEffect(async ({ url }: { url: string }) => {
  const { data } = await api.get(url)

  if (data.message) {
    toast.warning(data.message)
    return { warningMessage: data.message }
  }

  return { data }
})
