import styles from '../styles/Management.module.scss'

import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ArrowIcon from '../icons/ArrowIcon'
import noImage from '../../assets/images/no-image.jpg'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { IUser } from '../../types/user'
import { getAllUsers } from '../../api/users'
import { useUnit } from 'effector-react'
import { $user } from '../../store/user'
import MessageIcon from '../icons/MessageIcon'
import { formatDate } from '../../utils/formatDate'

export default function UserManagement() {
  const currentUser = useUnit($user)
  const [users, setUsers] = useState<IUser[]>([])
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const limit = 20
  const isMedia600 = useMediaQuery(600)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = Number(queryParams.get('page')) || 1
  const totalPages = Math.ceil(totalUsers / limit)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllUsers({
          url: `/users/all?page=${currentPage}&limit=${limit}`,
        })

        setUsers(data.users)

        setTotalUsers(data.totalUsers)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [currentPage])

  const renderPagination = () => {
    const buttons = []
    const viewLimit = !isMedia600 ? 8 : 3

    let startPage = Math.max(2, currentPage - Math.floor(viewLimit / 2))
    let endPage = startPage + viewLimit - 1

    if (endPage > totalPages - 1) {
      endPage = totalPages - 1
      startPage = Math.max(2, endPage - viewLimit + 1)
    }

    buttons.push(
      <Link
        className={currentPage === 1 ? styles.Active : ''}
        to={`?page=1`}
        key={1}
      >
        1
      </Link>,
    )

    if (startPage > 2) {
      buttons.push(<span key="start-ellipsis">...</span>)
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Link
          className={currentPage === i ? styles.Active : ''}
          to={`?page=${i}`}
          key={i}
        >
          {i}
        </Link>,
      )
    }

    if (endPage < totalPages - 1) {
      buttons.push(<span key="end-ellipsis">...</span>)
    }

    if (totalPages > 1) {
      buttons.push(
        <Link
          className={currentPage === totalPages ? styles.Active : ''}
          to={`?page=${totalPages}`}
          key={totalPages}
        >
          {totalPages}
        </Link>,
      )
    }

    return buttons
  }

  // const handleChangeStatus = async (id: number) => {
  //   try {
  //     await changeCardStatus({
  //       url: `/cards/changeStatus/${id}`,
  //       token: localStorage.getItem('token') || '',
  //     })

  //     setUsers(prevCards =>
  //       prevCards.map(card =>
  //         card.id === id ? { ...card, isOpen: !card.isOpen } : card,
  //       ),
  //     )
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  // const handleDeleteCard = async (id: number) => {
  //   try {
  //     await deleteCard({
  //       url: `/cards/delete/${id}`,
  //       token: localStorage.getItem('token') || '',
  //     })

  //     setUsers(prevCards => prevCards.filter(card => card.id !== id))
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  // const handleAddCard = async () => {
  //   const totalPages = Math.ceil((totalUsers + 1) / limit)

  //   navigate(`?page=${totalPages}`)

  //   try {
  //     const { data } = await createNewCard({
  //       url: `/cards/new`,
  //       token: localStorage.getItem('token') || '',
  //     })

  //     setUsers(prevCards => [...prevCards, data.card])
  //     setTotalUsers(totalUsers + 1)
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  return (
    <div className={`${styles.Management} ${styles.UserManagement}`}>
      <div className={styles.TableWrap}>
        <h2 className={styles.Title}>
          Управление пользователями (
          {`${currentPage * limit - limit + 1}/${currentPage * limit}`})
        </h2>
        {/* <button
          onClick={handleAddCard}
          className={styles.AddButton}
          title="Добавить новую карточку"
        >
          +
        </button> */}
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <div className={styles.Table}>
            <div className={styles.TableHead}>
              <span>id</span>
              <span>аватар</span>
              <span>ник</span>
              <span>Последний раз был в сети</span>
              <span>действия</span>
            </div>
            <div className={styles.TableMain}>
              {users.map((user: IUser) => {
                return (
                  <div className={styles.TableItem} key={user.id}>
                    <span>{user.id}</span>
                    <span className={styles.Image}>
                      <img
                        src={
                          user.avatar
                            ? `${process.env.REACT_APP_SERVER_URL}/media/avatars/${user.avatar}`
                            : noImage
                        }
                        alt={user.username}
                      />
                    </span>
                    <span>
                      {user.username}{' '}
                      {currentUser?.id === user.id ? '(Вы)' : ''}
                    </span>
                    {user.isOnline ? (
                      <span style={{ color: 'green' }}>Онлайн</span>
                    ) : (
                      <span className={styles.LastActive}>
                        {user?.lastActive
                          ? formatDate(user?.lastActive)
                          : 'Нет данных'}
                      </span>
                    )}
                    <span className={styles.Actions}>
                      <Link
                        to={`/messenger?chat=id${user?.id}`}
                        className={styles.WriteMessageButton}
                        title="Написать пользователю"
                      >
                        <MessageIcon />
                      </Link>
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Pagination}>
        <Link
          to={currentPage > 1 ? `?page=${currentPage - 1}` : ''}
          className={`${styles.PrevButton} ${currentPage === 1 ? styles.ButtonDisabled : ''}`}
        >
          <ArrowIcon />
        </Link>
        {renderPagination()}
        <Link
          to={currentPage < totalPages ? `?page=${currentPage + 1}` : ''}
          className={`${styles.NextButton} ${currentPage === totalPages ? styles.ButtonDisabled : ''} `}
        >
          <ArrowIcon />
        </Link>
      </div>
    </div>
  )
}
