// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cards_Cards__F7rKR {
  position: relative;
  left: 50%;
  top: 10vh;
  margin-bottom: 200px;
  display: grid;
  width: 100%;
  max-width: 1702px;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  overflow: hidden;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  grid-template-columns: repeat(34, 1fr)
}
@media (max-height: 920px) {
  .Cards_Cards__F7rKR {
    position: relative;
    top: 100px !important
  }
}
@media (max-width: 1800px) {
  .Cards_Cards__F7rKR {
    position: relative;
    max-width: 1402px;
    grid-template-columns: repeat(28, 1fr)
  }
}
@media (max-width: 1480px) {
  .Cards_Cards__F7rKR {
    position: relative;
    max-width: 1002px;
    grid-template-columns: repeat(20, 1fr)
  }
}
@media (max-width: 1080px) {
  .Cards_Cards__F7rKR {
    position: relative;
    max-width: 602px;
    grid-template-columns: repeat(12, 1fr)
  }
}
@media (max-width: 660px) {
  .Cards_Cards__F7rKR {
    position: relative;
    max-width: 302px;
    grid-template-columns: repeat(6, 1fr)
  }
}

.Cards_Tag__3DoKb {
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-user-select: none;
          user-select: none;
  text-align: center;
  font-size: 200px;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgb(216 216 216 / var(--tw-text-opacity));
  opacity: 0.4
}
@media (max-width: 1080px) {
  .Cards_Tag__3DoKb {
    position: absolute;
    font-size: 120px
  }
}
@media (max-width: 660px) {
  .Cards_Tag__3DoKb {
    position: absolute;
    font-size: 80px
  }
}`, "",{"version":3,"sources":["webpack://./src/components/modules/Cards/Cards.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAAA,SAAA;EAAA,SAAA;EAAA,oBAAA;EAAA,aAAA;EAAA,WAAA;EAAA,iBAAA;EAAA,sBAAA;EAAA,+LAAA;EAAA,gBAAA;EAAA,mBAAA;EAAA,iBAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,yDAAA;EAEA;AAFA;AAIA;EACE;IAAA,kBAAA;IAEA;EAFA;AAGJ;AAEE;EACE;IAAA,kBAAA;IAAA,iBAAA;IAEA;EAFA;AAGJ;AAEE;EACE;IAAA,kBAAA;IAAA,iBAAA;IAEA;EAFA;AAGJ;AAEE;EACE;IAAA,kBAAA;IAAA,gBAAA;IAEA;EAFA;AAGJ;AAEE;EACE;IAAA,kBAAA;IAAA,gBAAA;IAEA;EAFA;AAGJ;;AAIE;EAAA,oBAAA;EAAA,kBAAA;EAAA,SAAA;EAAA,QAAA;EAAA,WAAA;EAAA,sBAAA;EAAA,sBAAA;EAAA,+LAAA;EAAA,yBAAA;UAAA,iBAAA;EAAA,kBAAA;EAAA,gBAAA;EAAA,cAAA;EAAA,oBAAA;EAAA,gDAAA;EAAA;AAAA;AAEA;EACE;IAAA,kBAAA;IAAA;EAAA;AAEJ;AACE;EACE;IAAA,kBAAA;IAAA;EAAA;AAGJ","sourcesContent":[".Cards {\n  @apply relative left-[50%] top-[10vh] mb-[200px] grid w-full max-w-[1702px] -translate-x-2/4 overflow-hidden rounded-[12px] border border-solid border-white;\n\n  grid-template-columns: repeat(34, 1fr);\n\n  @media (max-height: 920px) {\n    @apply relative;\n\n    top: 100px !important;\n  }\n\n  @media (max-width: 1800px) {\n    @apply max-w-[1402px] relative;\n\n    grid-template-columns: repeat(28, 1fr);\n  }\n\n  @media (max-width: 1480px) {\n    @apply max-w-[1002px] relative;\n\n    grid-template-columns: repeat(20, 1fr);\n  }\n\n  @media (max-width: 1080px) {\n    @apply max-w-[602px] relative;\n\n    grid-template-columns: repeat(12, 1fr);\n  }\n\n  @media (max-width: 660px) {\n    @apply max-w-[302px] relative;\n\n    grid-template-columns: repeat(6, 1fr);\n  }\n}\n\n.Tag {\n  @apply pointer-events-none absolute left-2/4 top-2/4 z-10 -translate-x-2/4 -translate-y-2/4 select-none text-center text-[200px] leading-[1] text-[#d8d8d8] opacity-40;\n\n  @media (max-width: 1080px) {\n    @apply text-[120px] absolute;\n  }\n\n  @media (max-width: 660px) {\n    @apply text-[80px] absolute;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Cards": `Cards_Cards__F7rKR`,
	"Tag": `Cards_Tag__3DoKb`
};
export default ___CSS_LOADER_EXPORT___;
