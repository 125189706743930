import styles from './MediaItem.module.scss'
import { useMediaPreview } from '../../../context/MediaPreviewContext'

export default function MediaItem({ src }: { src: string }) {
  const { showMediaPreview } = useMediaPreview()

  return (
    <img
      onClick={() => showMediaPreview(src)}
      className={styles.Media}
      src={src}
      alt="Медиа-файл"
    />
  )
}
