// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthModule_AuthModule__xArQQ {
  position: absolute;
  left: 50%;
  top: 50vh;
  display: flex;
  height: 350px;
  width: 500px;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
  border-radius: 12px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 20px;
  box-shadow: 2px 3px 20px -8px black;
}
@media (max-width: 600px) {
  .AuthModule_AuthModule__xArQQ {
    width: 100%;
  }
}
@media (max-width: 380px) {
  .AuthModule_AuthModule__xArQQ {
    height: 250px;
    width: 100%;
    padding: 10px;
  }
}

.AuthModule_Input__VAMKj {
  position: relative;
  z-index: 10;
  height: 50px;
  width: 100%;
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  padding-left: 16px;
  padding-right: 16px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
.AuthModule_Input__VAMKj:focus {
  border-color: rgb(74 222 128 / 0.8);
}

.AuthModule_Button__BLTCP {
  position: relative;
  z-index: 10;
  height: 40px;
  width: 100%;
  border-radius: 12px;
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
.AuthModule_Button__BLTCP:hover {
  opacity: 0.9;
}

.AuthModule_Decoration__UpZhf img {
  position: absolute;
  width: 150px;
}
.AuthModule_Decoration__UpZhf img:nth-child(1) {
  bottom: -60px;
  left: -40px;
  display: block;
}
.AuthModule_Decoration__UpZhf img:nth-child(2) {
  right: -40px;
  top: -50px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/AuthModule/AuthModule.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAAA,SAAA;EAAA,SAAA;EAAA,aAAA;EAAA,aAAA;EAAA,YAAA;EAAA,sBAAA;EAAA,sBAAA;EAAA,+LAAA;EAAA,sBAAA;EAAA,uBAAA;EAAA,SAAA;EAAA,gBAAA;EAAA,mBAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,aAAA;EAEA;AAFA;AAIA;EACE;IAAA;EAAA;AAEJ;AACE;EACE;IAAA,aAAA;IAAA,WAAA;IAAA;EAAA;AAGJ;;AAEE;EAAA,kBAAA;EAAA,WAAA;EAAA,YAAA;EAAA,WAAA;EAAA,mBAAA;EAAA,iBAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,mDAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,8BAAA;EAAA,mBAAA;EAAA,+FAAA;EAAA,wDAAA;EAAA;AAAA;AAGE;EAAA;AAAA;;AAKF;EAAA,kBAAA;EAAA,WAAA;EAAA,YAAA;EAAA,WAAA;EAAA,mBAAA;EAAA,kBAAA;EAAA,wDAAA;EAAA,oBAAA;EAAA,gDAAA;EAAA,8BAAA;EAAA,mBAAA;EAAA,wBAAA;EAAA,wDAAA;EAAA;AAAA;AAEA;EACE,YAAA;AACJ;;AAKI;EAAA,kBAAA;EAAA;AAAA;AAGE;EAAA,aAAA;EAAA,WAAA;EAAA;AAAA;AAIA;EAAA,YAAA;EAAA,UAAA;EAAA;AAAA","sourcesContent":[".AuthModule {\n  @apply absolute left-2/4 top-[50vh] flex h-[350px] w-[500px] -translate-x-2/4 -translate-y-2/4 flex-col justify-center gap-[20px] overflow-hidden rounded-[12px] p-[20px] bg-white ;\n\n  box-shadow: 2px 3px 20px -8px black;\n\n  @media (max-width: 600px) {\n    @apply w-full;\n  }\n\n  @media (max-width: 380px) {\n    @apply p-[10px] h-[250px] w-full;\n  }\n}\n\n.Input {\n  @apply relative z-10 h-[50px] w-full rounded-[12px] border-[2px] border-solid border-black px-[16px] outline-none transition-colors duration-300;\n\n  &:focus {\n    @apply border-green-400/80;\n  }\n}\n\n.Button {\n  @apply relative z-10 h-[40px] w-full rounded-[12px] bg-green-400 text-white outline-none transition-all duration-200;\n\n  &:hover {\n    opacity: 0.9;\n  }\n}\n\n.Decoration {\n  img {\n    @apply w-[150px] absolute;\n\n    &:nth-child(1) {\n      @apply bottom-[-60px] left-[-40px] block;\n    }\n\n    &:nth-child(2) {\n      @apply right-[-40px] top-[-50px] block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthModule": `AuthModule_AuthModule__xArQQ`,
	"Input": `AuthModule_Input__VAMKj`,
	"Button": `AuthModule_Button__BLTCP`,
	"Decoration": `AuthModule_Decoration__UpZhf`
};
export default ___CSS_LOADER_EXPORT___;
