import { Outlet } from 'react-router-dom'
import DashboardSidebar from '../modules/DashboardSidebar'
import { useEffect } from 'react'

export default function DashboardLayout() {
  useEffect(() => {
    document.body.style.backgroundColor = '#1a222c'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <div className="wrapper">
      <DashboardSidebar />
      <Outlet />
    </div>
  )
}
