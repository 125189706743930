import styles from './Messenger.module.scss'
import Background from '../../components/elements/Background'
import Layout from '../../components/layout/Layout'
import Chat from '../../components/modules/Chat'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { IChat } from '../../types/chat'
import ChatList from '../../data/chatList'
import BackIcon from './icons/BackIcon'

export default function Messenger() {
  const isMobile = useMediaQuery(768)
  const [showChatList, setShowChatList] = useState(true)
  const chatList = ChatList()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const slug = searchParams.get('chat') || (!isMobile ? 'global' : '')

  const handleSelectChat = (item: IChat) => {
    navigate(`?chat=${item?.slug}`)

    if (isMobile) {
      setShowChatList(false)
    }
  }

  const handleExitChat = () => {
    setShowChatList(true)
    navigate(``)
  }

  return (
    <>
      <Background />
      <Layout>
        <div className={styles.Messenger}>
          {(isMobile && showChatList) || !isMobile ? (
            <ul className={styles.List}>
              {chatList.map(item => {
                return (
                  <li
                    className={`${styles.ListItem} ${item?.slug === slug ? styles.ListItemActive : ''}`}
                    key={item?.slug}
                    onClick={() => handleSelectChat(item)}
                  >
                    <img
                      className={styles.ChatImage}
                      src={item?.icon}
                      alt={item?.name}
                    />
                    {item?.name}
                  </li>
                )
              })}
            </ul>
          ) : null}
          {isMobile && !showChatList && (
            <button className={styles.BackButton} onClick={handleExitChat}>
              <BackIcon />
            </button>
          )}
          <Chat />
        </div>
      </Layout>
    </>
  )
}
