// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AudioPlayer_AudioPlayer__FLri3 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.AudioPlayer_ControlButton__CWCEn svg {
  width: 24px;
  height: 24px;
}

.AudioPlayer_Range__\\+crwy {
  height: 8px;
  width: 100%;
  cursor: pointer;
  appearance: none;
  border-radius: 5px;
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.AudioPlayer_Range__\\+crwy::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  appearance: none;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 127 80 / var(--tw-bg-opacity));
}
.AudioPlayer_Range__\\+crwy::-moz-range-thumb {
  height: 16px;
  width: 16px;
  appearance: none;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 127 80 / var(--tw-bg-opacity));
}

.AudioPlayer_Duration__w2hGr {
  position: absolute;
  bottom: 8px;
  left: 18px;
  display: flex;
  align-items: center;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/AudioPlayer/AudioPlayer.module.scss"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,mBAAA;EAAA;AAAA;;AAIA;EACE,WAAA;EACA,YAAA;AAAJ;;AAKE;EAAA,WAAA;EAAA,WAAA;EAAA,eAAA;EAAA,gBAAA;EAAA,kBAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,8BAAA;EAAA;AAAA;AAGE;EAAA,YAAA;EAAA,WAAA;EAAA,gBAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA;AAAA;AAIA;EAAA,YAAA;EAAA,WAAA;EAAA,gBAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA;AAAA;;AAKF;EAAA,kBAAA;EAAA,WAAA;EAAA,UAAA;EAAA,aAAA;EAAA,mBAAA;EAAA;AAAA","sourcesContent":[".AudioPlayer {\n  @apply items-center gap-[5px] flex;\n}\n\n.ControlButton {\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n}\n\n.Range {\n  @apply h-[8px] w-full cursor-pointer appearance-none rounded-[5px] bg-[#ddd] outline-none;\n\n  &::-webkit-slider-thumb {\n    @apply h-[16px] w-[16px] appearance-none rounded-full bg-coral;\n  }\n\n  &::-moz-range-thumb {\n    @apply h-[16px] w-[16px] appearance-none rounded-full bg-coral;\n  }\n}\n\n.Duration {\n  @apply bottom-[8px] left-[18px] flex items-center text-[12px] absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AudioPlayer": `AudioPlayer_AudioPlayer__FLri3`,
	"ControlButton": `AudioPlayer_ControlButton__CWCEn`,
	"Range": `AudioPlayer_Range__+crwy`,
	"Duration": `AudioPlayer_Duration__w2hGr`
};
export default ___CSS_LOADER_EXPORT___;
