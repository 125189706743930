export default function MicIcon() {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C9.06087 14 10.0783 13.5786 10.8284 12.8284C11.5786 12.0783 12 11.0609 12 10V4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4V10C4 11.0609 4.42143 12.0783 5.17157 12.8284C5.92172 13.5786 6.93913 14 8 14ZM6 4C6 3.46957 6.21071 2.96086 6.58579 2.58579C6.96086 2.21071 7.46957 2 8 2C8.53043 2 9.03914 2.21071 9.41421 2.58579C9.78929 2.96086 10 3.46957 10 4V10C10 10.5304 9.78929 11.0391 9.41421 11.4142C9.03914 11.7893 8.53043 12 8 12C7.46957 12 6.96086 11.7893 6.58579 11.4142C6.21071 11.0391 6 10.5304 6 10V4ZM16 10C16 9.73478 15.8946 9.48043 15.7071 9.29289C15.5196 9.10536 15.2652 9 15 9C14.7348 9 14.4804 9.10536 14.2929 9.29289C14.1054 9.48043 14 9.73478 14 10C14 11.5913 13.3679 13.1174 12.2426 14.2426C11.1174 15.3679 9.5913 16 8 16C6.4087 16 4.88258 15.3679 3.75736 14.2426C2.63214 13.1174 2 11.5913 2 10C2 9.73478 1.89464 9.48043 1.70711 9.29289C1.51957 9.10536 1.26522 9 1 9C0.734784 9 0.48043 9.10536 0.292893 9.29289C0.105357 9.48043 0 9.73478 0 10C0.00176579 11.9473 0.713722 13.8271 2.0024 15.287C3.29107 16.7469 5.06798 17.6866 7 17.93V20H5C4.73478 20 4.48043 20.1054 4.29289 20.2929C4.10536 20.4804 4 20.7348 4 21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22H11C11.2652 22 11.5196 21.8946 11.7071 21.7071C11.8946 21.5196 12 21.2652 12 21C12 20.7348 11.8946 20.4804 11.7071 20.2929C11.5196 20.1054 11.2652 20 11 20H9V17.93C10.932 17.6866 12.7089 16.7469 13.9976 15.287C15.2863 13.8271 15.9982 11.9473 16 10Z"
        fill="black"
      />
    </svg>
  )
}
