import { Navigate, Route, Routes } from 'react-router-dom'
import MainPage from './pages/MainPage'
import AdminPage from './pages/AdminPage'
import AdminLayout from './components/layout/DashboardLayout'
import CardManagement from './pages/CardManagement'
import CodeManagement from './pages/CodeManagement'
import { ProtectedRoute } from './components/elements/ProtectedRoute'
import { useCheckAdmin, useCheckAuth } from './hooks/useCheckUser'
import PrizeManagement from './pages/PrizeManagement'
import ProfilePage from './pages/ProfilePage'
import Messenger from './pages/Messenger'
import CardsPage from './pages/CardsPage'
import UserManagement from './pages/UserManagement'
import { useUnit } from 'effector-react'
import { $user } from './store/user'
import { ActivityDetector } from './components/elements/ActivityDetector'

export default function App() {
  const isLoggedIn = useCheckAuth()
  const isAdmin = useCheckAdmin()
  const user = useUnit($user)

  return (
    <>
      <ActivityDetector userId={String(user?.id) || ''} />
      <Routes>
        <Route index element={<MainPage />} />
        <Route path="/cards" element={<CardsPage />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              element={<AdminPage />}
              isLoggedIn={!isLoggedIn}
              routePath="/dashboard"
            />
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute
              element={<ProfilePage />}
              isLoggedIn={isLoggedIn}
              routePath="/"
            />
          }
        />
        <Route
          path="/messenger"
          element={
            <ProtectedRoute
              element={<Messenger />}
              isLoggedIn={isLoggedIn}
              routePath="/"
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              element={<AdminLayout />}
              isLoggedIn={isAdmin}
              routePath="/profile"
            />
          }
        >
          <Route
            path="/dashboard"
            element={<Navigate to="/dashboard/cards" />}
          />
          <Route path="/dashboard/cards" element={<CardManagement />} />
          <Route path="/dashboard/cards/prizes" element={<PrizeManagement />} />
          <Route path="/dashboard/codes" element={<CodeManagement />} />
          <Route path="/dashboard/users" element={<UserManagement />} />
        </Route>
        <Route path="*" element={<MainPage />} />
      </Routes>
    </>
  )
}
