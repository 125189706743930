// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MediaPreview_Media__gJV7W {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 20;
    height: auto;
    width: 240px;
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}`, "",{"version":3,"sources":["webpack://./src/components/modules/modals/MediaPreview/MediaPreview.module.scss"],"names":[],"mappings":"AACE;IAAA,kBAAA;IAAA,SAAA;IAAA,QAAA;IAAA,WAAA;IAAA,YAAA;IAAA,YAAA;IAAA,sBAAA;IAAA,sBAAA;IAAA;AAAA","sourcesContent":[".Media {\n  @apply absolute left-2/4 top-2/4 z-20 h-auto w-[240px] -translate-x-2/4 -translate-y-2/4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Media": `MediaPreview_Media__gJV7W`
};
export default ___CSS_LOADER_EXPORT___;
