// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeMood_ChangeMood__7atIv {
  position: absolute;
  right: -200px;
  top: -50px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 8px;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ChangeMood_Emoji__2NDU1 {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  padding: 6px;
  font-size: 24px;
  line-height: 1;
}
.ChangeMood_Emoji__2NDU1 svg {
  width: 24px;
  height: 24px;
}

.ChangeMood_SelectedEmoji__8HCIb {
  border-radius: 9999px;
  background-color: rgb(208 208 208 / 0.7);
}`, "",{"version":3,"sources":["webpack://./src/components/elements/ChangeMood/ChangeMood.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAAA,aAAA;EAAA,UAAA;EAAA,aAAA;EAAA,mBAAA;EAAA,SAAA;EAAA,mBAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,YAAA;EAAA,gFAAA;EAAA,oGAAA;EAAA;AAAA;;AAIA;EAAA,eAAA;EAAA,yBAAA;UAAA,iBAAA;EAAA,YAAA;EAAA,eAAA;EAAA;AAAA;AAEA;EACE,WAAA;EACA,YAAA;AACJ;;AAIE;EAAA,qBAAA;EAAA;AAAA","sourcesContent":[".ChangeMood {\n  @apply absolute right-[-200px] top-[-50px] flex items-center gap-[10px] rounded-[12px] bg-white p-[8px] shadow-xl;\n}\n\n.Emoji {\n  @apply cursor-pointer p-[6px] text-[24px] leading-[1] select-none;\n\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n}\n\n.SelectedEmoji {\n  @apply rounded-full bg-[#d0d0d0]/70;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChangeMood": `ChangeMood_ChangeMood__7atIv`,
	"Emoji": `ChangeMood_Emoji__2NDU1`,
	"SelectedEmoji": `ChangeMood_SelectedEmoji__8HCIb`
};
export default ___CSS_LOADER_EXPORT___;
