// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Backdrop_Backdrop__9638o {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 11;
    height: 100vh;
    width: 100vw;
    background-color: rgb(0 0 0 / 0.75)
}`, "",{"version":3,"sources":["webpack://./src/components/elements/Backdrop/Backdrop.module.scss"],"names":[],"mappings":"AACE;IAAA,eAAA;IAAA,SAAA;IAAA,QAAA;IAAA,WAAA;IAAA,aAAA;IAAA,YAAA;IAAA;AAAA","sourcesContent":[".Backdrop {\n  @apply fixed left-0 top-0 z-[11] h-screen w-screen bg-black/75;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": `Backdrop_Backdrop__9638o`
};
export default ___CSS_LOADER_EXPORT___;
