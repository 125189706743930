import AuthModule from '../../components/modules/AuthModule'
import Background from '../../components/elements/Background'
import { useEffect } from 'react'

export default function AdminPage() {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <>
      <Background />
      <AuthModule />
    </>
  )
}
