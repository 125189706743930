export default function PauseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6 6C9.28174 6 8.97652 6.12643 8.75148 6.35147C8.52643 6.57651 8.4 6.88174 8.4 7.2V16.8C8.4 17.1183 8.52643 17.4235 8.75148 17.6485C8.97652 17.8736 9.28174 18 9.6 18C9.91826 18 10.2235 17.8736 10.4485 17.6485C10.6736 17.4235 10.8 17.1183 10.8 16.8V7.2C10.8 6.88174 10.6736 6.57651 10.4485 6.35147C10.2235 6.12643 9.91826 6 9.6 6ZM12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 10.4241 23.6896 8.8637 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40042 18.0481 1.5165 16.5922 0.913446C15.1363 0.310389 13.5759 0 12 0ZM12 21.6C10.1013 21.6 8.24524 21.037 6.66653 19.9821C5.08782 18.9272 3.85736 17.4279 3.13076 15.6738C2.40416 13.9196 2.21405 11.9893 2.58447 10.1271C2.95488 8.26491 3.8692 6.55436 5.21178 5.21177C6.55436 3.86919 8.26492 2.95488 10.1271 2.58446C11.9894 2.21404 13.9196 2.40416 15.6738 3.13076C17.4279 3.85736 18.9272 5.08781 19.9821 6.66652C21.037 8.24524 21.6 10.1013 21.6 12C21.6 14.5461 20.5886 16.9879 18.7882 18.7882C16.9879 20.5886 14.5461 21.6 12 21.6ZM14.4 6C14.0817 6 13.7765 6.12643 13.5515 6.35147C13.3264 6.57651 13.2 6.88174 13.2 7.2V16.8C13.2 17.1183 13.3264 17.4235 13.5515 17.6485C13.7765 17.8736 14.0817 18 14.4 18C14.7183 18 15.0235 17.8736 15.2485 17.6485C15.4736 17.4235 15.6 17.1183 15.6 16.8V7.2C15.6 6.88174 15.4736 6.57651 15.2485 6.35147C15.0235 6.12643 14.7183 6 14.4 6Z"
        fill="white"
      />
    </svg>
  )
}
