export default function ArrowIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.92 10.38C13.8724 10.5028 13.801 10.6149 13.71 10.71L10.71 13.71C10.617 13.8037 10.5064 13.8781 10.3846 13.9289C10.2627 13.9797 10.132 14.0058 10 14.0058C9.86799 14.0058 9.73729 13.9797 9.61543 13.9289C9.49357 13.8781 9.38297 13.8037 9.29 13.71C9.19628 13.617 9.12188 13.5064 9.07111 13.3846C9.02034 13.2627 8.99421 13.132 8.99421 13C8.99421 12.868 9.02034 12.7373 9.07111 12.6154C9.12188 12.4936 9.19628 12.383 9.29 12.29L10.59 11H7C6.73479 11 6.48043 10.8946 6.2929 10.7071C6.10536 10.5196 6 10.2652 6 10C6 9.73479 6.10536 9.48043 6.2929 9.2929C6.48043 9.10536 6.73479 9 7 9H10.59L9.29 7.71C9.1017 7.5217 8.99591 7.26631 8.99591 7C8.99591 6.7337 9.1017 6.47831 9.29 6.29C9.47831 6.1017 9.7337 5.99591 10 5.99591C10.2663 5.99591 10.5217 6.1017 10.71 6.29L13.71 9.29C13.801 9.38511 13.8724 9.49725 13.92 9.62C14.02 9.86347 14.02 10.1365 13.92 10.38Z"
        fill="white"
      />
    </svg>
  )
}
