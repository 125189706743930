import ReactDOM from 'react-dom/client'
import './assets/styles/globals.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Toastify from './components/elements/Toastify'
import { MessageModalProvider } from './context/MessageModalContext'
import { ImageModalProvider } from './context/ImageModalContext'
import { MediaPreviewProvider } from './context/MediaPreviewContext'

window.addEventListener('error', event => {
  console.error('Global Error:', event.error)
  event.preventDefault()
})

window.addEventListener('unhandledrejection', event => {
  console.error('Unhandled Promise Rejection:', event.reason)
  event.preventDefault()
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <MessageModalProvider>
      <ImageModalProvider>
        <MediaPreviewProvider>
          <App />
          <Toastify />
        </MediaPreviewProvider>
      </ImageModalProvider>
    </MessageModalProvider>
  </BrowserRouter>,
)
