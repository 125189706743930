import { ReactNode } from 'react'
import Header from '../modules/Header'
import { useCheckAuth } from '../../hooks/useCheckUser'

export default function Layout({ children }: { children: ReactNode }) {
  const isLoggedIn = useCheckAuth()

  return (
    <>
      {isLoggedIn && <Header />}
      <div className="layout">
        {children}
      </div>
    </>
  )
}
