import { CSSProperties, useEffect } from 'react'
import styles from './Backdrop.module.scss'

export default function Backdrop({
  onClick,
  style,
}: {
  onClick?: () => void
  style?: CSSProperties
}) {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return <div className={styles.Backdrop} style={style} onClick={onClick} />
}
