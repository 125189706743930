import { INavigation } from '../types/navigation'
import CardsIcon from './icons/CardsIcon'
import CodesIcon from './icons/CodesIcon'
import HomeIcon from './icons/HomeIcon'
import PrizesIcon from './icons/PrizesIcon'
import UsersIcon from './icons/UsersIcon'

export const SidebarNavigation: INavigation[] = [
  {
    icon: <HomeIcon />,
    name: 'Главная',
    path: '/',
  },
  {
    icon: <CardsIcon />,
    name: 'Карточки',
    path: '/dashboard/cards',
  },
  {
    icon: <CodesIcon />,
    name: 'Коды',
    path: '/dashboard/codes',
  },
  {
    icon: <PrizesIcon />,
    name: 'Призы',
    path: '/dashboard/cards/prizes',
  },
  {
    icon: <UsersIcon />,
    name: 'Пользователи',
    path: '/dashboard/users',
  },
]
