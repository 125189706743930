import { useEffect } from 'react'
import io from 'socket.io-client'

export const ActivityDetector = ({ userId }: { userId: string }) => {
  useEffect(() => {
    const socket = io(process.env.REACT_APP_WS_URL, {
      query: { userId },
    })

    socket.connect()

    const handleDisconnect = () => {
      socket.disconnect()
    }

    const handleReconnect = () => {
      socket.connect()
    }

    let inactivityTimer: NodeJS.Timeout

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer)
      inactivityTimer = setTimeout(handleDisconnect, 60000)
      handleReconnect()
    }

    window.addEventListener('mousemove', resetInactivityTimer)
    window.addEventListener('keypress', resetInactivityTimer)
    window.addEventListener('click', resetInactivityTimer)
    window.addEventListener('scroll', resetInactivityTimer)

    resetInactivityTimer()

    return () => {
      clearTimeout(inactivityTimer)
      window.removeEventListener('mousemove', resetInactivityTimer)
      window.removeEventListener('keypress', resetInactivityTimer)
      window.removeEventListener('click', resetInactivityTimer)
      window.removeEventListener('scroll', resetInactivityTimer)
      socket.disconnect()
    }
  }, [userId])

  return null
}
