// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chat_Chat__Gj72N {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  border-left-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.Chat_AttachedMediaFiles__tn9DL {
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Chat_AttachedMediaFile__TMmep {
  position: relative;
  height: 96px;
  width: 96px;
}
.Chat_AttachedMediaFile__TMmep img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Chat_RemoveFileButton__THCp1 {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 5px;
  font-size: 12.7px;
  line-height: 8px;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.Chat_Header__WSmvY {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 10px;
  padding-bottom: 10px;
}
.Chat_Header__WSmvY img {
  height: 32px;
  width: 32px;
  border-radius: 9999px;
}
@media (max-width: 768px) {
  .Chat_Header__WSmvY {
    display: flex;
  }
}

.Chat_Footer__AR-bR {
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  gap: 10px;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Chat_Input__htk9B {
  height: 100%;
  flex: 1 1;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.Chat_ChatButton__cc5R5 {
  cursor: pointer;
}
.Chat_ChatButton__cc5R5 svg {
  width: 24px;
  height: 24px;
}

.Chat_Recording__9jVG6 {
  flex: 1 1;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/Chat/Chat.module.scss"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,YAAA;EAAA,WAAA;EAAA,sBAAA;EAAA,sBAAA;EAAA,sBAAA;EAAA;AAAA;;AAIA;EAAA,WAAA;EAAA,aAAA;EAAA,eAAA;EAAA,mBAAA;EAAA,SAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,iBAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,YAAA;EAAA;AAAA;AAGE;EAAA,YAAA;EAAA,WAAA;EAAA;AAAA;;AAKF;EAAA,kBAAA;EAAA,WAAA;EAAA,SAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,YAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,aAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,SAAA;EAAA,wBAAA;EAAA,sBAAA;EAAA,yDAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,iBAAA;EAAA;AAAA;AAGE;EAAA,YAAA;EAAA,WAAA;EAAA;AAAA;AAGF;EACE;IAAA;EAAA;AACJ;;AAIE;EAAA,aAAA;EAAA,YAAA;EAAA,WAAA;EAAA,mBAAA;EAAA,SAAA;EAAA,qBAAA;EAAA,sBAAA;EAAA,yDAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,iBAAA;EAAA;AAAA;;AAIA;EAAA,YAAA;EAAA,SAAA;EAAA,8BAAA;EAAA;AAAA;;AAIA;EAAA;AAAA;AAEA;EACE,WAAA;EACA,YAAA;AAAJ;;AAKE;EAAA,SAAA;EAAA;AAAA","sourcesContent":[".Chat {\n  @apply flex h-full w-full flex-col border-l border-gray-300;\n}\n\n.AttachedMediaFiles {\n  @apply z-20 flex-wrap items-center gap-[10px] bg-white px-[20px] py-[10px] flex;\n}\n\n.AttachedMediaFile {\n  @apply h-[96px] w-[96px] relative;\n\n  img {\n    @apply h-full w-full object-cover;\n  }\n}\n\n.RemoveFileButton {\n  @apply absolute right-[10px] top-[10px] rounded-full bg-white p-[5px] text-[12.7px] leading-[8px] text-black;\n}\n\n.Header {\n  @apply items-center justify-center gap-[10px] border-b border-gray-300 bg-white py-[10px] hidden;\n\n  img {\n    @apply h-[32px] w-[32px] rounded-full;\n  }\n\n  @media (max-width: 768px) {\n    @apply flex;\n  }\n}\n\n.Footer {\n  @apply h-[50px] w-full items-center gap-[10px] border-t border-gray-300 px-[20px] py-[10px] flex;\n}\n\n.Input {\n  @apply h-full flex-1 outline-none;\n}\n\n.ChatButton {\n  @apply cursor-pointer;\n\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n}\n\n.Recording {\n  @apply flex-1 cursor-default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Chat": `Chat_Chat__Gj72N`,
	"AttachedMediaFiles": `Chat_AttachedMediaFiles__tn9DL`,
	"AttachedMediaFile": `Chat_AttachedMediaFile__TMmep`,
	"RemoveFileButton": `Chat_RemoveFileButton__THCp1`,
	"Header": `Chat_Header__WSmvY`,
	"Footer": `Chat_Footer__AR-bR`,
	"Input": `Chat_Input__htk9B`,
	"ChatButton": `Chat_ChatButton__cc5R5`,
	"Recording": `Chat_Recording__9jVG6`
};
export default ___CSS_LOADER_EXPORT___;
