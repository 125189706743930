// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Messenger_Messenger__\\+jL2h {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  font-family: Roboto;
}

.Messenger_List__cFwc0 {
  display: flex;
  height: 97%;
  width: 220px;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .Messenger_List__cFwc0 {
    position: absolute;
    inset: 0px;
    z-index: 40;
    height: 100%;
    width: 100%;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
}

.Messenger_ListItem__5CroS {
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  border-radius: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}

.Messenger_ListItemActive__vg0My {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
@media (max-width: 768px) {
  .Messenger_ListItemActive__vg0My {
    background-color: transparent;
  }
}

.Messenger_ChatImage__\\+we3K {
  height: 48px;
  width: 48px;
  border-radius: 9999px;
}

.Messenger_BackButton__jvFxT {
  position: absolute;
  left: 12px;
  top: 20px;
}
.Messenger_BackButton__jvFxT svg {
  width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Messenger/Messenger.module.scss"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,YAAA;EAAA,WAAA;EAAA,mBAAA;EAAA;AAAA;;AAIA;EAAA,aAAA;EAAA,WAAA;EAAA,YAAA;EAAA,sBAAA;EAAA,SAAA;EAAA,cAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,iBAAA;EAAA;AAAA;AAEA;EACE;IAAA,kBAAA;IAAA,UAAA;IAAA,WAAA;IAAA,YAAA;IAAA,WAAA;IAAA,kBAAA;IAAA;EAAA;AAGJ;;AAEE;EAAA,aAAA;EAAA,eAAA;EAAA,yBAAA;UAAA,iBAAA;EAAA,mBAAA;EAAA,2BAAA;EAAA,QAAA;EAAA,mBAAA;EAAA,gBAAA;EAAA,mBAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA;AAAA;AAEA;EACE;IAAA;EAAA;AAIJ;;AACE;EAAA,YAAA;EAAA,WAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,UAAA;EAAA;AAAA;AAEA;EACE,WAAA;AAGJ","sourcesContent":[".Messenger {\n  @apply flex h-full w-full items-center font-roboto;\n}\n\n.List {\n  @apply overflow-auto h-[97%] w-[220px] flex-col gap-[15px] px-[10px] py-[20px] flex;\n\n  @media (max-width: 768px) {\n    @apply absolute inset-0 z-40 h-full w-full bg-white;\n  }\n}\n\n.ListItem {\n  @apply flex cursor-pointer select-none items-center justify-start gap-[5px] rounded-[12px] py-[8px] text-center;\n}\n\n.ListItemActive {\n  @apply bg-gray-200;\n\n  @media (max-width: 768px) {\n    @apply bg-transparent;\n  }\n}\n\n.ChatImage {\n  @apply h-[48px] w-[48px] rounded-full;\n}\n\n.BackButton {\n  @apply left-[12px] top-[20px] absolute;\n\n  svg {\n    width: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Messenger": `Messenger_Messenger__+jL2h`,
	"List": `Messenger_List__cFwc0`,
	"ListItem": `Messenger_ListItem__5CroS`,
	"ListItemActive": `Messenger_ListItemActive__vg0My`,
	"ChatImage": `Messenger_ChatImage__+we3K`,
	"BackButton": `Messenger_BackButton__jvFxT`
};
export default ___CSS_LOADER_EXPORT___;
