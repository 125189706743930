import { useUnit } from 'effector-react'
import styles from './ProfileSettings.module.scss'
import { $user, setUser } from '../../../store/user'
import AddIcon from './icons/AddIcon'
import { useEffect, useRef, useState } from 'react'
import ChangeMood from '../../elements/ChangeMood'
import { AnimatePresence, motion } from 'framer-motion'
import { updateName, updateStatus } from '../../../api/user'
import TextareaAutosize from 'react-textarea-autosize'
import EditIcon from './icons/EditIcon'
import UploadIcon from './icons/UploadIcon'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function ProfileSettings() {
  const user = useUnit($user)
  const [status, setStatus] = useState(user?.status)
  const [showChangeMood, setShowChangeMood] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [name, setName] = useState<string>(user?.name || 'Аноним')

  useEffect(() => {
    setName(user?.name || 'Аноним')
    setStatus(user?.status)
  }, [user])

  const moodRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (moodRef.current && !moodRef.current.contains(event.target as Node)) {
      setShowChangeMood(false)
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${name.length * 21}px`
    }
  }, [name, editMode])

  useEffect(() => {
    if (showChangeMood) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showChangeMood])

  const changeStatus = (value: string) => {
    setStatus(value)
  }

  const onUpdateStatus = async () => {
    if (user) {
      const { data } = await updateStatus({
        url: '/user/updateStatus',
        status: status || '',
        id: user.id,
      })
      setUser(data.user)
    }
  }

  const saveEdits = async () => {
    setEditMode(false)
    if (user && name && name !== 'Аноним' && name !== user.name) {
      try {
        const { data } = await updateName({
          url: '/user/updateName',
          name: name,
          id: user.id,
        })
        setUser(data.user)
      } catch (error: any) {
        const errorData = error.response.data
        if (errorData.errorCode === 'NAME_CHANGE_LIMIT') {
          toast.error(errorData.message)
        }
        setName(user.name)
      }
    } else if (name.length === 0) {
      setName('Аноним')
    }
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0]

    if (!file) {
      console.error('Необходимо указать файл')
      return
    }

    const formData = new FormData()
    formData.append('avatar', file)

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/user/uploadAvatar/${user?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      setUser(data.user)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Ошибка:', error.response.data)
      } else {
        console.error('Ошибка:', error)
      }
    }
  }

  if (!user) return null

  return (
    <div className={styles.ProfileSettings}>
      <button className={styles.EditButton} onClick={() => setEditMode(true)}>
        <EditIcon />
      </button>
      <div className={styles.AvatarContainer}>
        <img
          className={styles.Avatar}
          src={
            user?.avatar
              ? `${process.env.REACT_APP_SERVER_URL}/media/avatars/${user.avatar}?t=${Date.now()}`
              : '/images/avatar.jpg'
          }
          alt={user?.username}
        />
        {editMode && (
          <>
            <label htmlFor="file-upload" className={styles.EditAvatar}>
              <UploadIcon />
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".png, .jpg, .jpeg, .webp"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </>
        )}
      </div>
      <div className={styles.NameBox}>
        {!editMode ? (
          <span className={styles.Name}>{name}</span>
        ) : (
          <input
            ref={inputRef}
            type="text"
            className={styles.Name}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        )}
        {!editMode && (
          <span
            className={styles.Mood}
            onClick={() => setShowChangeMood(!showChangeMood)}
          >
            {user?.mood ? user?.mood : <AddIcon />}
          </span>
        )}
        <AnimatePresence>
          {showChangeMood && (
            <motion.div
              ref={moodRef}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
              style={{ position: 'absolute', right: '0', top: '0' }}
            >
              <ChangeMood />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <TextareaAutosize
        className={`${styles.Status} ${editMode ? styles.Hidden : ''}`}
        placeholder="Расскажите что-нибудь!"
        value={status}
        onChange={e => changeStatus(e.target.value)}
        maxLength={60}
        minRows={2}
        maxRows={2}
        onBlur={onUpdateStatus}
      />
      {editMode && (
        <button className={styles.SaveEditsButton} onClick={saveEdits}>
          Сохранить изменения
        </button>
      )}
    </div>
  )
}
