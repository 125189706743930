import styles from '../styles/Management.module.scss'
import DeleteIcon from '../icons/DeleteIcon'

import { useEffect, useState } from 'react'
import { ICard, IPrize } from '../../types/card'
import {
  changeCardStatus,
  deleteCard,
  getAllCards,
  changeCardPrize,
  createNewCard,
} from '../../api/cards'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import ArrowIcon from '../icons/ArrowIcon'
import noImage from '../../assets/images/no-image.jpg'
import axios from 'axios'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { decryptData } from '../../utils/decryptData'

export default function CardManagement() {
  const [cards, setCards] = useState<ICard[]>([])
  const [totalCards, setTotalCards] = useState<number>(0)
  const [prizes, setPrizes] = useState<IPrize[]>([])
  const limit = 20
  const isMedia600 = useMediaQuery(600)

  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = Number(queryParams.get('page')) || 1
  const totalPages = Math.ceil(totalCards / limit)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllCards({
          url: `/cards/all?page=${currentPage}&limit=${limit}`,
        })

        const decryptedCards = decryptData(data.cards)

        setCards(decryptedCards)

        setTotalCards(data.totalCards)
        setPrizes(
          decryptedCards.map((card: ICard) => ({
            id: card.id,
            text: card.prize || '',
          })),
        )
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [currentPage])

  const renderPagination = () => {
    const buttons = []
    const viewLimit = !isMedia600 ? 8 : 3

    let startPage = Math.max(2, currentPage - Math.floor(viewLimit / 2))
    let endPage = startPage + viewLimit - 1

    if (endPage > totalPages - 1) {
      endPage = totalPages - 1
      startPage = Math.max(2, endPage - viewLimit + 1)
    }

    buttons.push(
      <Link
        className={currentPage === 1 ? styles.Active : ''}
        to={`?page=1`}
        key={1}
      >
        1
      </Link>,
    )

    if (startPage > 2) {
      buttons.push(<span key="start-ellipsis">...</span>)
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Link
          className={currentPage === i ? styles.Active : ''}
          to={`?page=${i}`}
          key={i}
        >
          {i}
        </Link>,
      )
    }

    if (endPage < totalPages - 1) {
      buttons.push(<span key="end-ellipsis">...</span>)
    }

    if (totalPages > 1) {
      buttons.push(
        <Link
          className={currentPage === totalPages ? styles.Active : ''}
          to={`?page=${totalPages}`}
          key={totalPages}
        >
          {totalPages}
        </Link>,
      )
    }

    return buttons
  }

  const handleChangeStatus = async (id: number) => {
    try {
      await changeCardStatus({
        url: `/cards/changeStatus/${id}`,
        token: localStorage.getItem('token') || '',
      })

      setCards(prevCards =>
        prevCards.map(card =>
          card.id === id ? { ...card, isOpen: !card.isOpen } : card,
        ),
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteCard = async (id: number) => {
    try {
      await deleteCard({
        url: `/cards/delete/${id}`,
        token: localStorage.getItem('token') || '',
      })

      setCards(prevCards => prevCards.filter(card => card.id !== id))
    } catch (error) {
      console.error(error)
    }
  }

  const getPrizeById = (id: number) => {
    const prizeObject = prizes.find(prize => prize.id === id)
    return prizeObject ? prizeObject.text : ''
  }

  const handlePrizeChange = ({
    id,
    newPrize,
  }: {
    id: number
    newPrize: string
  }) => {
    setPrizes(prevPrizes =>
      prevPrizes.map(prize =>
        prize.id === id ? { ...prize, text: newPrize } : prize,
      ),
    )
  }

  const handleBlur = async (id: number) => {
    const prize = getPrizeById(id)
    try {
      await changeCardPrize({
        url: `/cards/changePrize/${id}`,
        token: localStorage.getItem('token') || '',
        newPrize: prize,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    if (event.target.files) {
      const file = event.target.files[0]

      const formData = new FormData()
      formData.append('photo', file)
      formData.append('token', localStorage.getItem('token') || '')

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/cards/uploadImage/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        setCards(prevCards =>
          prevCards.map(card =>
            card.id === id
              ? { ...card, image: response.data.card.image }
              : card,
          ),
        )

        toast.success('Фотография успешно обновлена!')
      } catch (error) {
        console.error('Ошибка загрузки фотографии:', error)
        alert('Ошибка при загрузке фотографии.')
      }
    }
  }

  const handleAddCard = async () => {
    const totalPages = Math.ceil((totalCards + 1) / limit)

    navigate(`?page=${totalPages}`)

    try {
      const { data } = await createNewCard({
        url: `/cards/new`,
        token: localStorage.getItem('token') || '',
      })

      setCards(prevCards => [...prevCards, data.card])
      setTotalCards(totalCards + 1)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={`${styles.Management} ${styles.CardManagement}`}>
      <div className={styles.TableWrap}>
        <h2 className={styles.Title}>
          Управление карточками (
          {`${currentPage * limit - limit + 1}/${currentPage * limit}`})
        </h2>
        <button
          onClick={handleAddCard}
          className={styles.AddButton}
          title="Добавить новую карточку"
        >
          +
        </button>
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <div className={styles.Table}>
            <div className={styles.TableHead}>
              <span>id</span>
              <span>изображение</span>
              <span>приз</span>
              <span>статус</span>
              <span>действия</span>
            </div>
            <div className={styles.TableMain}>
              {cards.map((item: ICard) => {
                return (
                  <div className={styles.TableItem} key={item.id}>
                    <span>{item.id}</span>
                    <span className={styles.Image}>
                      <img
                        src={
                          item.image
                            ? `${process.env.REACT_APP_SERVER_URL}/media/${item.image}`
                            : noImage
                        }
                        alt={item.prize}
                        onClick={() => {
                          const fileInput = document.getElementById(
                            `file-input-${item.id}`,
                          ) as HTMLInputElement
                          if (fileInput) {
                            fileInput.click()
                          }
                        }}
                      />
                      <input
                        id={`file-input-${item.id}`}
                        type="file"
                        accept="image/jpg, image/jpeg, image/png, image/webp"
                        style={{ display: 'none' }}
                        onChange={e => handleFileChange(e, item.id)}
                      />
                    </span>
                    <span>
                      <input
                        className={styles.Input}
                        value={getPrizeById(item.id)}
                        onChange={e =>
                          handlePrizeChange({
                            id: item.id,
                            newPrize: e.target.value,
                          })
                        }
                        onBlur={() => handleBlur(item.id)}
                      />
                    </span>
                    <span
                      onClick={() => handleChangeStatus(item.id)}
                      style={{
                        color: item.isOpen ? 'green' : 'red',
                      }}
                      className={styles.ChangeStatusButton}
                    >
                      {item.isOpen ? 'Открыта' : 'Закрыта'}
                    </span>
                    <span className={styles.Actions}>
                      <button
                        className={styles.DeleteButton}
                        onClick={() => handleDeleteCard(item.id)}
                        title="Удалить"
                      >
                        <DeleteIcon />
                      </button>
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Pagination}>
        <Link
          to={currentPage > 1 ? `?page=${currentPage - 1}` : ''}
          className={`${styles.PrevButton} ${currentPage === 1 ? styles.ButtonDisabled : ''}`}
        >
          <ArrowIcon />
        </Link>
        {renderPagination()}
        <Link
          to={currentPage < totalPages ? `?page=${currentPage + 1}` : ''}
          className={`${styles.NextButton} ${currentPage === totalPages ? styles.ButtonDisabled : ''} `}
        >
          <ArrowIcon />
        </Link>
      </div>
    </div>
  )
}
