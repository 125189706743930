export default function CodesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29">
      <path
        d="M13.196 6H6.804a.75.75 0 0 0-.75.75v6.5a.75.75 0 0 0 .75.75h6.392a.75.75 0 0 0 .75-.75v-6.5a.75.75 0 0 0-.75-.75Zm-.75 6.5H7.554v-5h4.892ZM11 9H9v2h2Zm-8 3a1 1 0 0 0 1-1V5a1.001 1.001 0 0 1 1-1h6a1 1 0 0 0 0-2H5a3.003 3.003 0 0 0-3 3v6a1 1 0 0 0 1 1Zm10.196 3H6.804a.75.75 0 0 0-.75.75v6.5a.75.75 0 0 0 .75.75h6.392a.75.75 0 0 0 .75-.75v-6.5a.75.75 0 0 0-.75-.75Zm-.75 6.5H7.554v-5h4.892ZM11 18H9v2h2Zm0 7H5a1.001 1.001 0 0 1-1-1v-6a1 1 0 0 0-2 0v6a3.003 3.003 0 0 0 3 3h6a1 1 0 0 0 0-2Zm15-8a1 1 0 0 0-1 1v6a1.001 1.001 0 0 1-1 1h-6a1 1 0 0 0 0 2h6a3.003 3.003 0 0 0 3-3v-6a1 1 0 0 0-1-1Zm-8.5 3.5h-2v2h2Zm-2-3.006h2v-2h-2ZM24 2h-6a1 1 0 0 0 0 2h6a1.001 1.001 0 0 1 1 1v6a1 1 0 0 0 2 0V5a3.003 3.003 0 0 0-3-3Zm-6 9h2V9h-2Zm2 9v-2h-2v2Zm2.196-6a.75.75 0 0 0 .75-.75v-6.5a.75.75 0 0 0-.75-.75h-6.392a.75.75 0 0 0-.75.75v6.5a.75.75 0 0 0 .75.75Zm-5.642-6.5h4.892v5h-4.892Zm5.946 7.994h-2v2h2Zm-2 7.006h2v-2h-2Z"
        fill="#dee4ee"
      ></path>
    </svg>
  )
}
