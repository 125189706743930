import { useRef, useState } from 'react'
import { ICard } from '../../../types/card'
import styles from './Card.module.scss'
import { toast } from 'react-toastify'
import { openCard } from '../../../api/cards'

import TestImage from '../../../assets/images/patrick.webp'
import animationData from './loader.json'
import Lottie from 'lottie-react'
import axios from 'axios'
import { useImageModal } from '../../../context/ImageModalContext'

export default function Card({
  hasPermission,
  content,
  code,
}: {
  hasPermission: boolean
  content: ICard
  code: string
}) {
  const [open, setOpen] = useState(content.isOpen)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isOpening, setIsOpening] = useState(false)
  const { showImageModal } = useImageModal()

  const openContent = async () => {
    if (!hasPermission) {
      toast.error('Вы не можете открыть эту карточку')
      return
    }

    try {
      await Promise.all([
        openCard({ url: `/cards/opening/${content.id}`, code }),
      ])

      setIsOpening(true)

      setTimeout(async () => {
        setIsOpening(false)
        setOpen(true)

        showImageModal(content)

        try {
          await axios.post(`${process.env.REACT_APP_SERVER_URL}/send-message`, {
            text: `Пользователь открыл карточку №${content.id}.
Ему выпал приз: ${content.prize}
Он израсходовал код: ${code}`,
          })
        } catch (error) {
          console.error(error)
        }
      }, 2000)
    } catch (error) {
      console.error(error)
      toast.error('Вы не можете открыть эту карточку')
    }
  }

  return (
    <div
      className={`${styles.Card} ${isOpening ? styles.OpeningCard : ''} ${open ? styles.OpenCard : ''}`}
      onClick={openContent}
      ref={containerRef}
    >
      {isOpening && (
        <Lottie
          animationData={animationData}
          loop={true}
          className={styles.Animation}
        />
      )}
      {open && (
        <img
          className={styles.Image}
          src={
            content.image
              ? `${process.env.REACT_APP_SERVER_URL}/media/${content.image}`
              : TestImage
          }
          alt="Patrick"
        />
      )}
    </div>
  )
}
