import { ChangeEvent, useState } from 'react'
import styles from '../styles/Management.module.scss'
import Cookies from 'js-cookie'
import { changeCardPrizes } from '../../api/cards'
import { toast } from 'react-toastify'

export default function PrizeManagement() {
  const [output, setOutput] = useState('')
  const saveButtonText = 'Всё верно. Сохранить призы в карточки'

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const reader = new FileReader()

    reader.onload = e => {
      const text = e.target?.result as string
      processText(text)
    }

    reader.readAsText(file)
  }

  const processText = (text: string) => {
    const lines = text.split('\n')
    let result: string[] = []

    lines.forEach(line => {
      const match = line.match(/=(.*?)\s*\[(\d+)\]/)
      if (match) {
        const word = match[1].trim()
        const count = parseInt(match[2], 10)
        for (let i = 0; i < count; i++) {
          result.push(word)
        }
      } else {
        const matchNoCount = line.match(/=(.*)/)
        if (matchNoCount) {
          const word = matchNoCount[1].trim()
          result.push(word)
        }
      }
    })

    setOutput(result.join(', '))
  }

  const handleChange = (value: string) => {
    setOutput(value.replace(/,+/g, ','))
  }

  const handleSavePrizes = async () => {
    try {
      await changeCardPrizes({
        url: '/cards/changePrizes',
        token: localStorage.getItem('token') || '',
        dataPrizes: output,
      })

      setOutput('')
      toast.success('Призы успешно сохранены!')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={`${styles.Management} ${styles.PrizeManagement}`}>
      <h1 className={`${styles.Title} !w-full text-center`}>
        Загрузите .txt файл
      </h1>
      <p className={styles.PrizesHint}>
        После загрузки файла, проверьте корректность данных, и после нажмите на
        кнопку "{saveButtonText}".
      </p>
      {!output ? (
        <>
          <input
            type="file"
            id="file"
            accept=".txt"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />

          <label htmlFor="file" className={styles.UploadButton}>
            Загрузите файл
          </label>
        </>
      ) : (
        <>
          <textarea
            className={styles.Textarea}
            value={output}
            onChange={e => handleChange(e.target.value)}
          ></textarea>
          <button
            className={styles.SavePrizesButton}
            onClick={handleSavePrizes}
          >
            {saveButtonText}
          </button>
        </>
      )}
    </div>
  )
}
