import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './Header.module.scss'
import { useCheckAdmin } from '../../../hooks/useCheckUser'
import LogoutIcon from './icons/LogoutIcon'
import { logoutFx } from '../../../api/auth'
import { useMediaQuery } from '../../../hooks/useMediaQuery'

export default function Header() {
  const isAdmin = useCheckAdmin()
  const location = useLocation()
  const navigate = useNavigate()
  const isMedia380 = useMediaQuery(380)

  const logout = async () => {
    try {
      await logoutFx({
        url: '/auth/logout',
      })

      localStorage.setItem('token', '')
      window.location.reload()

      navigate('/')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <header
      className={`${styles.Header} ${location.pathname === '/cards' ? styles.HeaderCards : ''}`}
    >
      <ul className={styles.List}>
        <li>
          <Link to="/cards">Карточки</Link>
        </li>
        <li>
          <Link to="/profile">Профиль</Link>
        </li>
        <li>
          <Link to="/messenger">Мессенджер</Link>
        </li>
        {isAdmin && (
          <li>
            <Link to="/admin">{!isMedia380 ? 'Админ-панель' : 'Панель'}</Link>
          </li>
        )}
      </ul>
      <button
        className={styles.LogoutButton}
        onClick={logout}
        title="Выйти из аккаунта"
        aria-label="Выйти из аккаунта"
      >
        <LogoutIcon />
      </button>
    </header>
  )
}
