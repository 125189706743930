import { useEffect, useRef, useState } from 'react'
import styles from './CardStatistics.module.scss'
import { getCardStatistics } from '../../../api/statistics'
import getStatTranslate from '../../../utils/getStatTranslate'
import CountUp from 'react-countup'

export default function CardStatistics() {
  const [stat, setStat] = useState([])
  const hasRun = useRef(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getCardStatistics({
          url: `/statistics/cards`,
        })

        setStat(data)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()

    const intervalId = setInterval(fetchData, 5000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className={styles.CardStatistics}>
      {Object.entries(stat).map(([key, value]) => (
        <div className={styles.Part} key={key}>
          <strong>{getStatTranslate(key)}:</strong>
          <CountUp
            end={value}
            duration={2.5}
            onEnd={() => (hasRun.current = true)}
            start={hasRun.current ? value - 1 : 0}
          />
        </div>
      ))}
    </div>
  )
}
