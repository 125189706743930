export default function TrashIcon() {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4 19.2C8.71826 19.2 9.02348 19.0736 9.24853 18.8485C9.47357 18.6235 9.6 18.3183 9.6 18V10.8C9.6 10.4817 9.47357 10.1765 9.24853 9.95147C9.02348 9.72643 8.71826 9.6 8.4 9.6C8.08174 9.6 7.77652 9.72643 7.55147 9.95147C7.32643 10.1765 7.2 10.4817 7.2 10.8V18C7.2 18.3183 7.32643 18.6235 7.55147 18.8485C7.77652 19.0736 8.08174 19.2 8.4 19.2ZM20.4 4.8H15.6V3.6C15.6 2.64522 15.2207 1.72955 14.5456 1.05442C13.8705 0.379285 12.9548 0 12 0H9.6C8.64522 0 7.72955 0.379285 7.05442 1.05442C6.37928 1.72955 6 2.64522 6 3.6V4.8H1.2C0.88174 4.8 0.576515 4.92643 0.351472 5.15147C0.126428 5.37652 0 5.68174 0 6C0 6.31826 0.126428 6.62348 0.351472 6.84853C0.576515 7.07357 0.88174 7.2 1.2 7.2H2.4V20.4C2.4 21.3548 2.77928 22.2705 3.45442 22.9456C4.12955 23.6207 5.04522 24 6 24H15.6C16.5548 24 17.4705 23.6207 18.1456 22.9456C18.8207 22.2705 19.2 21.3548 19.2 20.4V7.2H20.4C20.7183 7.2 21.0235 7.07357 21.2485 6.84853C21.4736 6.62348 21.6 6.31826 21.6 6C21.6 5.68174 21.4736 5.37652 21.2485 5.15147C21.0235 4.92643 20.7183 4.8 20.4 4.8ZM8.4 3.6C8.4 3.28174 8.52643 2.97652 8.75147 2.75147C8.97652 2.52643 9.28174 2.4 9.6 2.4H12C12.3183 2.4 12.6235 2.52643 12.8485 2.75147C13.0736 2.97652 13.2 3.28174 13.2 3.6V4.8H8.4V3.6ZM16.8 20.4C16.8 20.7183 16.6736 21.0235 16.4485 21.2485C16.2235 21.4736 15.9183 21.6 15.6 21.6H6C5.68174 21.6 5.37652 21.4736 5.15147 21.2485C4.92643 21.0235 4.8 20.7183 4.8 20.4V7.2H16.8V20.4ZM13.2 19.2C13.5183 19.2 13.8235 19.0736 14.0485 18.8485C14.2736 18.6235 14.4 18.3183 14.4 18V10.8C14.4 10.4817 14.2736 10.1765 14.0485 9.95147C13.8235 9.72643 13.5183 9.6 13.2 9.6C12.8817 9.6 12.5765 9.72643 12.3515 9.95147C12.1264 10.1765 12 10.4817 12 10.8V18C12 18.3183 12.1264 18.6235 12.3515 18.8485C12.5765 19.0736 12.8817 19.2 13.2 19.2Z"
        fill="black"
      />
    </svg>
  )
}
