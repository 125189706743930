import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

export function ProtectedRoute({
  element,
  isLoggedIn,
  routePath,
}: {
  element: ReactElement
  isLoggedIn: boolean | null
  routePath?: string
}) {
  if (isLoggedIn === null) {
    return <></>
  }

  return isLoggedIn ? element : <Navigate to={routePath || '/admin'} replace />
}
