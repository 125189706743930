// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MediaItem_Media__jWBXr {

    max-height: 128px;

    width: 128px;

    cursor: pointer;

    object-fit: cover
}

.MediaItem_ModalMedia__huAnL {

    position: absolute;

    left: 50%;

    top: 50%;

    z-index: 30;

    width: 40%;

    --tw-translate-x: -50%;

    --tw-translate-y: -50%;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.MediaItem_CloseModalButton__aBaP3 {

    position: absolute;

    right: 30px;

    top: 40px;

    font-size: 40px
}`, "",{"version":3,"sources":["webpack://./src/components/elements/MediaItem/MediaItem.module.scss"],"names":[],"mappings":"AACE;;IAAA,iBAAA;;IAAA,YAAA;;IAAA,eAAA;;IAAA;AAAA;;AAIA;;IAAA,kBAAA;;IAAA,SAAA;;IAAA,QAAA;;IAAA,WAAA;;IAAA,UAAA;;IAAA,sBAAA;;IAAA,sBAAA;;IAAA;AAAA;;AAIA;;IAAA,kBAAA;;IAAA,WAAA;;IAAA,SAAA;;IAAA;AAAA","sourcesContent":[".Media {\n  @apply max-h-[128px] w-[128px] cursor-pointer object-cover;\n}\n\n.ModalMedia {\n  @apply absolute left-2/4 top-2/4 z-30 w-[40%] -translate-x-2/4 -translate-y-2/4;\n}\n\n.CloseModalButton {\n  @apply right-[30px] top-[40px] text-[40px] absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Media": `MediaItem_Media__jWBXr`,
	"ModalMedia": `MediaItem_ModalMedia__huAnL`,
	"CloseModalButton": `MediaItem_CloseModalButton__aBaP3`
};
export default ___CSS_LOADER_EXPORT___;
