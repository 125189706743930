import { useUnit } from 'effector-react'
import { $user } from '../store/user'
import { useEffect, useState } from 'react'
import { IUser } from '../types/user'
import { getAllUsers } from '../api/users'

export default function ChatList() {
  const user = useUnit($user)
  const [allUsers, setAllUsers] = useState<IUser[]>()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllUsers({
          url: `/users/all`,
        })

        setAllUsers(data.users)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return [
    { name: 'Общий чат', slug: 'global', icon: '/images/general-chat.jpeg' },
    ...(user?.permission === 'admin' && Array.isArray(allUsers)
      ? allUsers
          .filter((user: IUser) => user.permission !== 'admin')
          .map((user: IUser) => ({
            name: `${user.username}`,
            slug: `id${String(user.id)}`,
            icon: user.avatar
              ? `${process.env.REACT_APP_SERVER_URL}/media/avatars/${user.avatar}`
              : '/images/avatar.jpg',
          }))
      : [
          {
            name: 'Администратор',
            slug: `id${String(user?.id)}`,
            icon: '/images/admin-chat.jpg',
          },
        ]),
  ]
}
