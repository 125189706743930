export default function PlayIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 9.924L10.8 6.456C10.4353 6.24546 10.0217 6.13457 9.60062 6.13446C9.17954 6.13435 8.76584 6.24503 8.40107 6.45538C8.03629 6.66574 7.73329 6.96836 7.52247 7.33286C7.31165 7.69736 7.20044 8.11092 7.2 8.532V15.468C7.20044 15.8889 7.31154 16.3022 7.52216 16.6666C7.73278 17.031 8.03552 17.3336 8.4 17.544C8.76485 17.7546 9.17872 17.8655 9.6 17.8655C10.0213 17.8655 10.4352 17.7546 10.8 17.544L16.8 14.076C17.1637 13.865 17.4656 13.5622 17.6754 13.1979C17.8853 12.8335 17.9958 12.4205 17.9958 12C17.9958 11.5795 17.8853 11.1665 17.6754 10.8021C17.4656 10.4378 17.1637 10.135 16.8 9.924ZM15.6 12L9.6 15.468V8.532L15.6 12ZM12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 10.4241 23.6896 8.8637 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40042 18.0481 1.5165 16.5922 0.913446C15.1363 0.310389 13.5759 0 12 0ZM12 21.6C10.1013 21.6 8.24524 21.037 6.66653 19.9821C5.08782 18.9272 3.85736 17.4279 3.13076 15.6738C2.40416 13.9196 2.21405 11.9893 2.58447 10.1271C2.95488 8.26491 3.8692 6.55436 5.21178 5.21177C6.55436 3.86919 8.26492 2.95488 10.1271 2.58446C11.9894 2.21404 13.9196 2.40416 15.6738 3.13076C17.4279 3.85736 18.9272 5.08781 19.9821 6.66652C21.037 8.24524 21.6 10.1013 21.6 12C21.6 14.5461 20.5886 16.9879 18.7882 18.7882C16.9879 20.5886 14.5461 21.6 12 21.6Z"
        fill="white"
      />
    </svg>
  )
}
