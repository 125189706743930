import { useEffect, useState } from 'react'
import { ICode } from '../types/code'
import { decryptData } from '../utils/decryptData'
import { getAllCodes } from '../api/codes'

export const useCheckPermission = (code: string | null) => {
  const [codes, setCodes] = useState<ICode[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllCodes({
          url: `/codes/all`,
        })

        const decryptedCodes = decryptData(data.codes)

        setCodes(decryptedCodes)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  if (code) {
    const codeEntry = codes.find((item: ICode) => item.text === code)
    if (codeEntry && !codeEntry.isUsed) {
      return true
    }
    return false
  } else {
    return false
  }
}
