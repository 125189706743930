import { useEffect, useState } from 'react'
import Backdrop from '../../../elements/Backdrop'
import styles from './MediaPreview.module.scss'

export default function MediaPreview({ src }: { src: string }) {
  const [show, setShow] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [src])

  const handleCloseModal = () => {
    setShow(false)
  }

  return show ? (
    <>
      <Backdrop onClick={handleCloseModal} />
      <img className={styles.Media} src={src} alt="Медиа" />
    </>
  ) : (
    <></>
  )
}
