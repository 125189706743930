export default function NothingIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3823 0.400024C5.77618 0.400024 0.399902 5.78419 0.399902 12.4C0.399902 15.6728 1.73519 18.664 3.87867 20.8458C3.87867 20.8458 3.87867 20.881 3.91381 20.881H3.94895C6.12758 23.0628 9.1144 24.4 12.4175 24.4C19.0236 24.4 24.3999 19.0159 24.3999 12.4C24.3999 5.78419 18.9885 0.400024 12.3823 0.400024ZM12.3823 2.08917C14.9123 2.08917 17.2315 3.00413 19.0236 4.51733L4.54632 19.0511C3.03533 17.2563 2.12172 14.9337 2.12172 12.4C2.08658 6.73433 6.72494 2.08917 12.3823 2.08917ZM12.3823 22.7109C9.85232 22.7109 7.53314 21.7959 5.74105 20.2475L20.2184 5.749C21.7645 7.54372 22.6781 9.8663 22.6781 12.4C22.6781 18.0657 18.0397 22.7109 12.3823 22.7109Z"
        fill="#9A9A9A"
      />
    </svg>
  )
}
