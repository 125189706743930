import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './DashboardSidebar.module.scss'
import LogoutIcon from './icons/LogoutIcon'
import { SidebarNavigation } from '../../../data/navigation'
import { logoutFx } from '../../../api/auth'
import { useEffect, useState } from 'react'

export default function DashboardSidebar() {
  const location = useLocation()
  const navigate = useNavigate()
  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  const logout = async () => {
    try {
      await logoutFx({
        url: '/auth/logout',
      })

      localStorage.setItem('token', '')
      window.location.reload()

      navigate('/admin')
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (openMobileMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [openMobileMenu])

  useEffect(() => {
    setOpenMobileMenu(false)
  }, [location])

  return (
    <>
      <button
        className={`${styles.OpenButton} ${openMobileMenu ? styles.OpenButtonActive : ''}`}
        onClick={() => setOpenMobileMenu(!openMobileMenu)}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div
        className={`${styles.DashboardSidebar} ${openMobileMenu ? styles.DashboardSidebarMobileOpen : ''}`}
      >
        <nav className={styles.Navigation}>
          <ul className={styles.NavigationList}>
            {SidebarNavigation.map(item => {
              return (
                <li className={styles.NavigationListItem} key={item.path}>
                  <Link
                    className={`${styles.NavigationLink} ${location.pathname === item.path ? styles.NavigationLinkActive : ''}`}
                    to={item.path}
                  >
                    {item.icon}
                    {item.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
        <div className={styles.ControlButtons}>
          <button
            onClick={logout}
            className={styles.ControlButton}
            title="Выйти из аккаунта"
          >
            <LogoutIcon />
          </button>
        </div>
      </div>
    </>
  )
}
