import Card from '../Card'
import styles from './Cards.module.scss'
import { useEffect, useState } from 'react'
import { getAllCards } from '../../../api/cards'
import { useLocation } from 'react-router-dom'
import { ICard } from '../../../types/card'
import MessageModal from '../modals/MessageModal'
import { decryptData } from '../../../utils/decryptData'
import LoginModal from '../modals/LoginModal'
import { useCheckPermission } from '../../../hooks/useCheckPermission'
import { useCheckAuth } from '../../../hooks/useCheckUser'

export default function Cards() {
  const isLoggedIn = useCheckAuth()
  const [cards, setCards] = useState<ICard[]>([])
  const [enteredCode, setEnteredCode] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllCards({
          url: `/cards/all`,
        })

        const decryptedCards = decryptData(data.cards)

        setCards(decryptedCards)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('code')

  const [hasPermission, setHasPermission] = useState<boolean>(false)

  const hasLinkPermission = useCheckPermission(code)

  return (
    <>
      {!isLoggedIn &&
        (hasLinkPermission ? (
          <MessageModal
            message="Приветик, ковбой! У тебя есть 1 попытка для открытия карточки. Испытай
          свою удачу, йо-хо-хо!"
          />
        ) : (
          <LoginModal
            message="Привет! Чтобы продолжить, введи свой уникальный код, который позволит тебе открыть карточку."
            value={enteredCode}
            setValue={setEnteredCode}
            setHasPermission={setHasPermission}
          />
        ))}

      <div className={styles.Cards}>
        <span className={styles.Tag}>Bikini Bottom</span>
        {cards.map((card, index) => (
          <Card
            hasPermission={hasPermission || hasLinkPermission}
            code={enteredCode || code || ''}
            content={card}
            key={index}
          />
        ))}
      </div>
    </>
  )
}
