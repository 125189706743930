import { useUnit } from 'effector-react'
import { IUser } from '../../../types/user'
import styles from './Message.module.scss'
import { $user } from '../../../store/user'
import { formatTimestamp } from '../../../utils/formatTime'
// import MediaItem from '../MediaItem'
// import AudioPlayer from '../AudioPlayer'
import { IMessage } from '../../../types/message'
import { useEffect, useState } from 'react'
import { getAllUsers } from '../../../api/users'
import { useSearchParams } from 'react-router-dom'
import MediaItem from '../MediaItem'
import AudioPlayer from '../AudioPlayer'

export default function Message({ msg }: { msg: IMessage }) {
  const [user, setUser] = useState<IUser | null>(null)
  const [searchParams] = useSearchParams()
  const slug = searchParams.get('chat')

  const isAdmin = slug && /^id\d+$/.test(slug) && user?.permission === 'admin'
  const name = isAdmin ? 'Администратор' : user?.name || 'Аноним'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllUsers({
          url: `/users/all`,
        })

        const users = data.users

        const user = users.find((user: IUser) => user.id === msg.userId)

        setUser(user)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  const selfUser = useUnit($user)

  return (
    <div
      className={`${styles.Message} ${selfUser?.id === msg.userId ? styles.SelfMessage : ''}`}
    >
      <img
        className={styles.Avatar}
        src={
          isAdmin
            ? '/images/admin-chat.jpg'
            : user?.avatar
              ? `${process.env.REACT_APP_SERVER_URL}/media/avatars/${user.avatar}`
              : '/images/avatar.jpg'
        }
        alt={name}
      />
      <div className={styles.Content}>
        <span className={styles.Name}>
          {name}
          {!isAdmin && user?.mood}
        </span>
        {msg.type === 'image' ? (
          <MediaItem src={`${process.env.REACT_APP_SERVER_URL}${msg.url}`} />
        ) : msg.type === 'audio' ? (
          <AudioPlayer
            src={`${process.env.REACT_APP_SERVER_URL}${msg.url}`}
            audioDuration={msg.audioDuration}
          />
        ) : (
          msg.msg
        )}
        <span className={styles.Timestamp}>
          {msg.timestamp ? formatTimestamp(msg.timestamp) : ''}
        </span>
      </div>
    </div>
  )
}
