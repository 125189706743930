import { createEffect } from 'effector'
import { toast } from 'react-toastify'
import api from './axiosClient'

export const getAllCodes = createEffect(async ({ url }: { url: string }) => {
  const { data } = await api.get(url)

  if (data.message) {
    toast.warning(data.message)
    return { warningMessage: data.message }
  }

  return { data }
})

export const changeCodeStatus = createEffect(
  async ({ url, token }: { url: string; token: string }) => {
    const { data } = await api.post(url, { token })

    return { data }
  },
)

export const createNewCode = createEffect(
  async ({ url, token }: { url: string; token: string }) => {
    const { data } = await api.post(url, { token })

    return { data }
  },
)

export const deleteCode = createEffect(
  async ({ url, token }: { url: string; token: string }) => {
    const { data } = await api.post(url, { token })

    return { data }
  },
)
