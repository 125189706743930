import { useEffect, useState } from 'react'
import Backdrop from '../../../elements/Backdrop'
import styles from '../styles/Modal.module.scss'
import { useCheckPermission } from '../../../../hooks/useCheckPermission'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { login, register } from '../../../../api/auth'
import { setUser } from '../../../../store/user'
import ArrowIcon from '../icons/ArrowIcon'

export default function LoginModal({
  message,
  value,
  setValue,
  setHasPermission,
}: {
  message: string
  value: string
  setValue: (value: string) => void
  setHasPermission: (hasPermission: boolean) => void
}) {
  const [show, setShow] = useState(true)
  const [enterCodeMode, setEnterCodeMode] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const navigate = useNavigate()
  const [authMode, setAuthMode] = useState<'login' | 'register'>('login')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isValid = useCheckPermission(value)

  const submitCode = () => {
    if (isValid) {
      setShow(false)
      setHasPermission(true)
    } else {
      toast.error('Код недействительный')
      setHasPermission(false)
    }
  }

  const onLogin = async () => {
    try {
      const { data } = await login({
        url: '/auth/login',
        username: username,
        password: password,
      })

      localStorage.setItem('token', data.token)
      setUser(data.user)

      toast.success('Авторизация прошла успешно')

      window.location.reload()
      navigate('/profile')
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка')
    }
  }

  const onRegister = async () => {
    try {
      const { data } = await register({
        url: '/auth/register',
        username: username,
        password: password,
      })

      localStorage.setItem('token', data.token)
      setUser(data.user)

      toast.success('Регистрация прошла успешна')

      window.location.reload()
      navigate('/profile')
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка')
    }
  }

  const handleSwitchMode = () => {
    setUsername('')
    setPassword('')
    setRepeatPassword('')

    setAuthMode(authMode === 'login' ? 'register' : 'login')
  }

  return show ? (
    <>
      <Backdrop />
      {enterCodeMode ? (
        <div className={`${styles.Modal} ${styles.EnterCodeModal}`}>
          <h3 className={styles.Message}>{message}</h3>
          <input
            className={styles.Input}
            placeholder="Введите ваш код"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <button
            className={`${styles.NextButton} ${!value ? styles.NextButtonDisabled : ''}`}
            onClick={submitCode}
          >
            Далее
          </button>
          <button
            className={styles.VipButton}
            onClick={() => setEnterCodeMode(false)}
          >
            VIP
          </button>
        </div>
      ) : (
        <div className={`${styles.Modal} ${styles.LoginModal}`}>
          <button
            className={styles.EnterCodeButton}
            onClick={() => setEnterCodeMode(true)}
          >
            <ArrowIcon />
          </button>
          <h3 className={styles.Message}>
            {authMode === 'login'
              ? 'Если у тебя есть аккаунт, ты можешь войти в него!'
              : 'Если у тебя ещё нет аккаунта, ты можешь зарегистрировать его!'}
          </h3>
          {authMode === 'login' ? (
            <>
              <input
                type="text"
                className={styles.AuthInput}
                placeholder="Логин"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <input
                type="password"
                className={styles.AuthInput}
                placeholder="Пароль"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <button
                className={styles.AuthButton}
                onClick={onLogin}
                disabled={username.length <= 3 || password.length <= 3}
              >
                Войти
              </button>
            </>
          ) : (
            <>
              <input
                type="text"
                className={styles.AuthInput}
                placeholder="Логин"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <input
                type="password"
                className={styles.AuthInput}
                placeholder="Пароль"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <input
                type="password"
                className={styles.AuthInput}
                placeholder="Введите пароль ещё раз"
                value={repeatPassword}
                onChange={e => setRepeatPassword(e.target.value)}
              />
              <button
                className={styles.AuthButton}
                onClick={onRegister}
                disabled={
                  username.length <= 3 ||
                  password.length <= 3 ||
                  password !== repeatPassword
                }
              >
                Зарегистрироваться
              </button>
            </>
          )}

          <button className={styles.SwitchAuth} onClick={handleSwitchMode}>
            {authMode === 'register' ? 'Войти' : 'Зарегистрироваться'}
          </button>
        </div>
      )}
    </>
  ) : (
    <></>
  )
}
