import { useEffect, useState } from 'react'
import Backdrop from '../../../elements/Backdrop'
import styles from '../styles/Modal.module.scss'
import Confetti from 'react-confetti'
import { motion } from 'framer-motion'
import { ICard } from '../../../../types/card'
import { useMessageModal } from '../../../../context/MessageModalContext'

export default function ImageModal({ content }: { content: ICard }) {
  const [show, setShow] = useState(true)
  const { showModal } = useMessageModal()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [content])

  const handleCloseModal = () => {
    setShow(false)
    showModal(
      content.prize
        ? `Поздравляю! Ты выиграл ${content.prize}`
        : 'Упс, пусто. В следующий раз повезёт!',
    )
  }

  return show ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Backdrop style={{ zIndex: 70 }} onClick={handleCloseModal} />
      <img
        className={styles.Image}
        src={`${process.env.REACT_APP_SERVER_URL}/media/${content.image}`}
        alt={String(content.id)}
        onClick={handleCloseModal}
      />
      <Confetti
        tweenDuration={2}
        style={{ zIndex: 70, width: '100vw', height: '100vh' }}
      />
    </motion.div>
  ) : (
    <></>
  )
}
