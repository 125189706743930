import React, { createContext, useContext, useState, ReactNode } from 'react'
import MediaPreview from '../components/modules/modals/MediaPreview'

interface MediaPreviewContextType {
  showMediaPreview: (src: string) => void
}

const MediaPreviewContext = createContext<MediaPreviewContextType | undefined>(
  undefined,
)

export const MediaPreviewProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [source, setSource] = useState<string | null>(null)

  const showMediaPreview = (src: string) => {
    setSource(src)
  }

  return (
    <MediaPreviewContext.Provider value={{ showMediaPreview }}>
      {children}
      {source && <MediaPreview src={source} />}
    </MediaPreviewContext.Provider>
  )
}

export const useMediaPreview = () => {
  const context = useContext(MediaPreviewContext)
  if (!context) {
    throw new Error('useImageModal must be used within a ImageModalProvider')
  }
  return context
}
