export function formatDate(date: Date | string) {
  const parsedDate = typeof date === 'string' ? new Date(date) : date

  const day = String(parsedDate.getDate()).padStart(2, '0')
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
  const year = parsedDate.getFullYear()

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }
  const time = parsedDate.toLocaleTimeString('ru-RU', options)

  return `${day}-${month}-${year} ${time}`
}

export const formatChatDate = (date: Date, timezone: string) => {
  const now = new Date()
  const messageDate = new Date(date)

  const nowFormatted = new Intl.DateTimeFormat('ru-RU', {
    timeZone: timezone,
  }).format(now)
  const messageDateFormatted = new Intl.DateTimeFormat('ru-RU', {
    timeZone: timezone,
  }).format(messageDate)

  const isSameDay = nowFormatted === messageDateFormatted
  const isSameYear = now.getFullYear() === messageDate.getFullYear()

  if (isSameDay) {
    return 'Сегодня'
  }

  if (isSameYear) {
    return new Intl.DateTimeFormat('ru-RU', {
      day: '2-digit',
      month: 'long',
      timeZone: timezone,
    }).format(messageDate)
  }

  return new Intl.DateTimeFormat('ru-RU', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    timeZone: timezone,
  }).format(messageDate)
}
