import { createDomain } from 'effector'
import { IUser } from '../types/user'
import { getInfo } from '../api/user'

const userDomain = createDomain()

export const setUser = userDomain.createEvent<IUser | null>()

export const fetchUserFx = userDomain.createEffect<void, IUser>({
  handler: async () => {
    const { data } = await getInfo({
      url: '/user/getInfo',
    })
    return data
  },
})

export const $user = userDomain
  .createStore<IUser | null>(null)
  .on(setUser, (_, user) => user)
  .on(fetchUserFx.doneData, (_, user) => user)

fetchUserFx()
