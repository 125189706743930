// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Message_Message__qcvQC {
  display: flex;
  align-items: flex-end;
  gap: 10px
}

.Message_SelfMessage__hQJ\\+H {
  margin-left: auto;
  flex-direction: row-reverse
}
.Message_SelfMessage__hQJ\\+H .Message_Content__so9zt {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity))
}
.Message_SelfMessage__hQJ\\+H .Message_Content__so9zt::before {
  left: initial;
  right: -6px;
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  border-bottom-left-radius: 16px 14px;
  border-bottom-right-radius: 0
}
.Message_SelfMessage__hQJ\\+H .Message_Content__so9zt::after {
  position: absolute;
  left: initial;
  right: -25.6px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0
}

.Message_Avatar__X39hv {
  z-index: 10;
  height: 32px;
  width: 32px;
  border-radius: 9999px
}

.Message_Content__so9zt {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
  border-radius: 16px;
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}
.Message_Content__so9zt:before, .Message_Content__so9zt:after {
  position: absolute;
  bottom: 1px;
  z-index: 0;
  height: 25px;
  content: ""
}
.Message_Content__so9zt::before {
  left: -6px;
  width: 20px;
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
  border-bottom-right-radius: 16px 14px
}
.Message_Content__so9zt::after {
  left: -25.6px;
  width: 25.6px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-bottom-right-radius: 10px
}

.Message_Name__SXSGN {
  font-size: 10px;
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity))
}

.Message_Timestamp__138HP {
  text-align: right;
  font-size: 10px;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/components/elements/Message/Message.module.scss"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,qBAAA;EAAA;AAAA;;AAIA;EAAA,iBAAA;EAAA;AAAA;AAGE;EAAA,kBAAA;EAAA;AAAA;AAGE;EAAA,aAAA;EAAA,WAAA;EAAA,kBAAA;EAAA,wDAAA;EAEA,oCAAA;EACA;AAHA;AAOA;EAAA,kBAAA;EAAA,aAAA;EAAA,cAAA;EAEA,+BAAA;EACA;AAHA;;AASJ;EAAA,WAAA;EAAA,YAAA;EAAA,WAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,aAAA;EAAA,sBAAA;EAAA,QAAA;EAAA,mBAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA,oBAAA;EAAA;AAAA;AAIE;EAAA,kBAAA;EAAA,WAAA;EAAA,UAAA;EAAA,YAAA;EAEA;AAFA;AAMA;EAAA,UAAA;EAAA,WAAA;EAAA,kBAAA;EAAA,yDAAA;EAEA;AAFA;AAMA;EAAA,aAAA;EAAA,aAAA;EAAA,kBAAA;EAAA,yDAAA;EAEA;AAFA;;AAOF;EAAA,eAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,iBAAA;EAAA,eAAA;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".Message {\n  @apply items-end gap-[10px] flex;\n}\n\n.SelfMessage {\n  @apply ml-auto flex-row-reverse;\n\n  .Content {\n    @apply bg-blue-500;\n\n    &::before {\n      @apply left-[initial] right-[-6px] bg-blue-500;\n\n      border-bottom-left-radius: 16px 14px;\n      border-bottom-right-radius: 0;\n    }\n\n    &::after {\n      @apply left-[initial] right-[-25.6px] absolute;\n\n      border-bottom-left-radius: 10px;\n      border-bottom-right-radius: 0;\n    }\n  }\n}\n\n.Avatar {\n  @apply z-10 h-[32px] w-[32px] rounded-full;\n}\n\n.Content {\n  @apply relative flex flex-col gap-[3px] rounded-[16px] bg-gray-400 px-[16px] py-[10px] text-white;\n\n  &:before,\n  &:after {\n    @apply bottom-[1px] z-0 h-[25px] absolute;\n\n    content: '';\n  }\n\n  &::before {\n    @apply left-[-6px] w-[20px] bg-gray-400;\n\n    border-bottom-right-radius: 16px 14px;\n  }\n\n  &::after {\n    @apply left-[-25.6px] w-[25.6px] bg-white;\n\n    border-bottom-right-radius: 10px;\n  }\n}\n\n.Name {\n  @apply text-[10px] text-gray-200;\n}\n\n.Timestamp {\n  @apply text-right text-[10px] text-gray-300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Message": `Message_Message__qcvQC`,
	"SelfMessage": `Message_SelfMessage__hQJ+H`,
	"Content": `Message_Content__so9zt`,
	"Avatar": `Message_Avatar__X39hv`,
	"Name": `Message_Name__SXSGN`,
	"Timestamp": `Message_Timestamp__138HP`
};
export default ___CSS_LOADER_EXPORT___;
