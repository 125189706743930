import styles from './MessageList.module.scss'
import type { IMessage } from '../../../types/message'
import Message from '../../elements/Message'
import { useEffect, useRef, useState } from 'react'
import { formatChatDate } from '../../../utils/formatDate'

export default function MessageList({ messages }: { messages: IMessage[] }) {
  const chatEndRef = useRef<HTMLDivElement>(null)
  const [isAtBottom, setIsAtBottom] = useState(true)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  let lastDate: string | null = null

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const atBottom =
      target.scrollHeight - target.scrollTop === target.clientHeight
    setIsAtBottom(atBottom)
  }

  useEffect(() => {
    if (isAtBottom) {
      chatEndRef.current?.scrollIntoView({ behavior: 'auto' })
    }
  }, [messages, isAtBottom])

  return (
    <div className={styles.MessageList} onScroll={handleScroll}>
      {messages.map((msg: IMessage, index: number) => {
        const messageDate = formatChatDate(msg.timestamp, timezone)
        const showDate = messageDate !== lastDate
        lastDate = messageDate

        return (
          <div key={index}>
            {showDate && <div className={styles.DateLabel}>{messageDate}</div>}
            <Message msg={msg} />
          </div>
        )
      })}
      <div ref={chatEndRef} />
    </div>
  )
}
