import { useRef, useState } from 'react'
import styles from './AudioPlayer.module.scss'
import PauseIcon from './icons/PauseIcon'
import PlayIcon from './icons/PlayIcon'
import { formatRecordingTime } from '../../../utils/formatTime'

export default function AudioPlayer({
  src,
  audioDuration,
}: {
  src: string
  audioDuration?: number
}) {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(audioDuration || 0)
  const [currentTime, setCurrentTime] = useState(0)

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause()
      } else {
        audioRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current) {
      const newProgress = Number(e.target.value)
      audioRef.current.currentTime =
        (audioRef.current.duration * newProgress) / 100
      setProgress(newProgress)
    }
  }

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const current = audioRef.current.currentTime
      const totalDuration = audioRef.current.duration
      setCurrentTime(current)
      const currentProgress = (current / totalDuration) * 100
      setProgress(currentProgress)
    }
  }

  return (
    <div className={styles.AudioPlayer}>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)}
      />

      <button className={styles.ControlButton} onClick={togglePlayPause}>
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </button>

      <input
        className={styles.Range}
        type="range"
        value={progress}
        min={0}
        max={100}
        onChange={handleProgressChange}
      />

      <div className={styles.Duration}>
        {formatRecordingTime(currentTime)} / {formatRecordingTime(duration)}
      </div>
    </div>
  )
}
