export default function PrizesIcon() {
  return (
    <svg
      width="54"
      height="56"
      viewBox="0 0 54 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.14 13.16C39.5321 12.96 39.9097 12.7328 40.27 12.48C43.19 10.43 44.09 6.65996 42.27 4.07996C40.45 1.49996 36.61 1.07996 33.69 3.14996C30.77 5.21996 29.27 8.87996 27.64 13.15L39.14 13.16ZM3.70001 26.46H50.31V50.16C50.31 51.2208 49.8886 52.2382 49.1384 52.9884C48.3883 53.7385 47.3709 54.16 46.31 54.16H7.70001C6.63915 54.16 5.62173 53.7385 4.87158 52.9884C4.12144 52.2382 3.70001 51.2208 3.70001 50.16V26.46Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.85 26.46H21.15V54.16H32.85V26.46Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.86 13.16C14.4699 12.9622 14.0954 12.7349 13.74 12.48C10.82 10.43 9.91001 6.65996 11.74 4.07996C13.57 1.49996 17.4 1.07996 20.33 3.14996C23.26 5.21996 24.74 8.87996 26.38 13.15L14.86 13.16Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5 13.16H4.5C2.84315 13.16 1.5 14.5032 1.5 16.16V23.46C1.5 25.1169 2.84315 26.46 4.5 26.46H49.5C51.1569 26.46 52.5 25.1169 52.5 23.46V16.16C52.5 14.5032 51.1569 13.16 49.5 13.16Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.92 13.16H20.09V26.46H33.92V13.16Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
