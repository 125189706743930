// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_Header__PKamW {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
  height: 60px;
  width: calc(100% - 80px);
  max-width: 1800px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  padding: 20px;
  font-family: Roboto;
}
@media (max-width: 768px) {
  .Header_Header__PKamW {
    margin-bottom: 0px;
    width: 100%;
    gap: 15px;
    border-radius: 0px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
  }
}

.Header_HeaderCards__-1V2J {
  position: absolute;
  left: 50%;
  margin-bottom: 0px;
  max-width: 1702px;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (max-width: 1800px) {
  .Header_HeaderCards__-1V2J {
    max-width: 1402px;
  }
}
@media (max-width: 1480px) {
  .Header_HeaderCards__-1V2J {
    max-width: 1002px;
  }
}
@media (max-width: 1080px) {
  .Header_HeaderCards__-1V2J {
    max-width: 602px;
  }
}
@media (max-width: 660px) {
  .Header_HeaderCards__-1V2J {
    max-width: 100%;
  }
}

.Header_List__MtocL {
  display: flex;
  height: 100%;
  align-items: center;
  gap: 20px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media (max-width: 768px) {
  .Header_List__MtocL {
    gap: 15px;
  }
}

.Header_LogoutButton__WBAz8 svg {
  width: 24px;
  height: 24px;
}
@media (max-width: 768px) {
  .Header_LogoutButton__WBAz8 svg {
    width: 20px;
    height: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/modules/Header/Header.module.scss"],"names":[],"mappings":"AACE;EAAA,iBAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,aAAA;EAAA,YAAA;EAAA,wBAAA;EAAA,iBAAA;EAAA,mBAAA;EAAA,8BAAA;EAAA,SAAA;EAAA,gCAAA;EAAA,+BAAA;EAAA,kBAAA;EAAA,mDAAA;EAAA,aAAA;EAAA;AAAA;AAEA;EACE;IAAA,kBAAA;IAAA,WAAA;IAAA,SAAA;IAAA,kBAAA;IAAA,kBAAA;IAAA,mBAAA;IAAA;EAAA;AAGJ;;AAEE;EAAA,kBAAA;EAAA,SAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,sBAAA;EAAA;AAAA;AAEA;EAHF;IAII,iBAAA;EAGF;AACF;AADE;EAPF;IAQI,iBAAA;EAIF;AACF;AAFE;EAXF;IAYI,gBAAA;EAKF;AACF;AAHE;EACE;IAAA;EAAA;AAOJ;;AAFE;EAAA,aAAA;EAAA,YAAA;EAAA,mBAAA;EAAA,SAAA;EAAA,oBAAA;EAAA;AAAA;AAEA;EAHF;IAII,SAAA;EAOF;AACF;;AAHE;EACE,WAAA;EACA,YAAA;AAMJ;AAHE;EACE;IACE,WAAA;IACA,YAAA;EAKJ;AACF","sourcesContent":[".Header {\n  @apply mx-auto mb-[20px] flex h-[60px] w-[calc(100%-80px)] max-w-[1800px] items-center justify-between gap-[20px] rounded-b-[24px] bg-black p-[20px] font-roboto;\n\n  @media (max-width: 768px) {\n    @apply mb-0 w-full gap-[15px] px-[15px] text-[12px] rounded-none;\n  }\n}\n\n.HeaderCards {\n  @apply absolute left-2/4 mb-0 max-w-[1702px] -translate-x-2/4;\n\n  @media (max-width: 1800px) {\n    max-width: 1402px;\n  }\n\n  @media (max-width: 1480px) {\n    max-width: 1002px;\n  }\n\n  @media (max-width: 1080px) {\n    max-width: 602px;\n  }\n\n  @media (max-width: 660px) {\n    @apply max-w-full;\n  }\n}\n\n.List {\n  @apply flex h-full items-center gap-[20px] text-white;\n\n  @media (max-width: 768px) {\n    gap: 15px;\n  }\n}\n\n.LogoutButton {\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n\n  @media (max-width: 768px) {\n    svg {\n      width: 20px;\n      height: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `Header_Header__PKamW`,
	"HeaderCards": `Header_HeaderCards__-1V2J`,
	"List": `Header_List__MtocL`,
	"LogoutButton": `Header_LogoutButton__WBAz8`
};
export default ___CSS_LOADER_EXPORT___;
