import styles from '../styles/Management.module.scss'
import DeleteIcon from '../icons/DeleteIcon'

import { useEffect, useState } from 'react'
import { ICode } from '../../types/code'
import {
  changeCodeStatus,
  deleteCode,
  getAllCodes,
  createNewCode,
} from '../../api/codes'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import ArrowIcon from '../icons/ArrowIcon'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { decryptData } from '../../utils/decryptData'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import DownloadIcon from '../icons/DownloadIcon'
import QRCode from 'qrcode'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

export default function CodeManagement() {
  const [codes, setCodes] = useState<ICode[]>([])
  const [totalCodes, setTotalCodes] = useState<number>(0)
  const limit = 20
  const isMedia600 = useMediaQuery(600)

  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = Number(queryParams.get('page')) || 1
  const totalPages = Math.ceil(totalCodes / limit)

  const generateQRCodeDataURL = (text: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(text, { width: 200 }, (err, url) => {
        if (err) {
          reject(err)
        } else {
          resolve(url)
        }
      })
    })
  }

  const handleDownloadQRCode = async (code: ICode) => {
    try {
      const link = `${process.env.REACT_APP_CLIENT_URL}?code=${code.text}`
      const dataURL = await generateQRCodeDataURL(link)

      const response = await fetch(dataURL)
      const blob = await response.blob()

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = `qrcode_${code.id}.jpg`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      console.error(
        `Error generating or downloading QR code for ${code.text}:`,
        error,
      )
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllCodes({
          url: `/codes/all?page=${currentPage}&limit=${limit}`,
        })

        const decryptedCodes = decryptData(data.codes)

        setCodes(decryptedCodes)
        setTotalCodes(data.totalCodes)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [currentPage])

  const generateQRCodeBlob = (text: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      QRCode.toCanvas(text, { width: 200 }, (err, canvas) => {
        if (err) {
          return reject(err)
        }
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob)
          } else {
            reject(new Error('Failed to convert canvas to Blob'))
          }
        }, 'image/png')
      })
    })
  }

  const downloadAllCodes = async () => {
    try {
      const { data } = await getAllCodes({
        url: `/codes/all`,
      })

      const decryptedCodes = decryptData(data.codes)

      const zip = new JSZip()

      const qrPromises = decryptedCodes.map(
        async (code: any, index: number) => {
          const link = `${process.env.REACT_APP_CLIENT_URL}?code=${code.text}`
          try {
            const blob = await generateQRCodeBlob(link)
            zip.file(`qrcode_${index + 1}.png`, blob)
          } catch (error) {
            console.error(`Error generating QR code for ${code.text}:`, error)
          }
        },
      )

      const chunkSize = 50
      for (let i = 0; i < qrPromises.length; i += chunkSize) {
        await Promise.all(qrPromises.slice(i, i + chunkSize))
        await new Promise(resolve => setTimeout(resolve, 100))
      }

      const content = await zip.generateAsync({ type: 'blob' })
      saveAs(content, 'qrcodes.zip')
    } catch (error) {
      console.error('Error generating QR codes or downloading ZIP file:', error)
    }
  }

  const renderPagination = () => {
    const buttons = []
    const viewLimit = !isMedia600 ? 8 : 3

    let startPage = Math.max(2, currentPage - Math.floor(viewLimit / 2))
    let endPage = startPage + viewLimit - 1

    if (endPage > totalPages - 1) {
      endPage = totalPages - 1
      startPage = Math.max(2, endPage - viewLimit + 1)
    }

    buttons.push(
      <Link
        className={currentPage === 1 ? styles.Active : ''}
        to={`?page=1`}
        key={1}
      >
        1
      </Link>,
    )

    if (startPage > 2) {
      buttons.push(<span key="start-ellipsis">...</span>)
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Link
          className={currentPage === i ? styles.Active : ''}
          to={`?page=${i}`}
          key={i}
        >
          {i}
        </Link>,
      )
    }

    if (endPage < totalPages - 1) {
      buttons.push(<span key="end-ellipsis">...</span>)
    }

    if (totalPages > 1) {
      buttons.push(
        <Link
          className={currentPage === totalPages ? styles.Active : ''}
          to={`?page=${totalPages}`}
          key={totalPages}
        >
          {totalPages}
        </Link>,
      )
    }

    return buttons
  }

  const handleChangeStatus = async (id: number) => {
    try {
      await changeCodeStatus({
        url: `/codes/changeStatus/${id}`,
        token: localStorage.getItem('token') || '',
      })

      setCodes(prevCodes =>
        prevCodes.map(code =>
          code.id === id ? { ...code, isUsed: !code.isUsed } : code,
        ),
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteCode = async (id: number) => {
    try {
      await deleteCode({
        url: `/codes/delete/${id}`,
        token: localStorage.getItem('token') || '',
      })

      setCodes(prevCodes => prevCodes.filter(code => code.id !== id))
    } catch (error) {
      console.error(error)
    }
  }

  const handleCreateCode = async () => {
    const totalPages = Math.ceil((totalCodes + 1) / limit)

    navigate(`?page=${totalPages}`)

    try {
      const { data } = await createNewCode({
        url: `/codes/new`,
        token: localStorage.getItem('token') || '',
      })

      setCodes(prevCodes => [...prevCodes, data.code])
      setTotalCodes(totalCodes + 1)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={`${styles.Management} ${styles.CodeManagement}`}>
      <div className={styles.TableWrap}>
        <h2 className={styles.Title}>
          Управление кодами (
          {`${currentPage * limit - limit + 1}/${currentPage * limit}`})
        </h2>
        <button onClick={downloadAllCodes} className={styles.DownloadAllCode}>
          Скачать всё
        </button>
        <button
          onClick={handleCreateCode}
          className={styles.AddButton}
          title="Создать новый код"
        >
          +
        </button>
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <div className={styles.Table}>
            <div className={styles.TableHead}>
              <span>id</span>
              <span>код</span>
              <span>статус</span>
              <span>действия</span>
            </div>
            <div className={styles.TableMain}>
              {codes.map((item: ICode) => {
                const link = `${process.env.REACT_APP_CLIENT_URL}?code=${item.text}`
                return (
                  <div className={styles.TableItem} key={item.id}>
                    <span>{item.id}</span>
                    <CopyToClipboard
                      text={link}
                      onCopy={() =>
                        toast.success('Код скопирован в буфер обмена')
                      }
                    >
                      <span className={styles.Code}>{item.text}</span>
                    </CopyToClipboard>
                    <button onClick={() => handleDownloadQRCode(item)}>
                      <DownloadIcon />
                    </button>
                    <span
                      onClick={() => handleChangeStatus(item.id)}
                      style={{
                        color: item.isUsed ? 'green' : 'red',
                      }}
                      className={styles.ChangeStatusButton}
                    >
                      {item.isUsed ? 'Использован' : 'Не использован'}
                    </span>
                    <span className={styles.Actions}>
                      <button
                        className={styles.DeleteButton}
                        onClick={() => handleDeleteCode(item.id)}
                        title="Удалить"
                      >
                        <DeleteIcon />
                      </button>
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Pagination}>
        <Link
          to={currentPage > 1 ? `?page=${currentPage - 1}` : ''}
          className={`${styles.PrevButton} ${currentPage === 1 ? styles.ButtonDisabled : ''}`}
        >
          <ArrowIcon />
        </Link>
        {renderPagination()}
        <Link
          to={currentPage < totalPages ? `?page=${currentPage + 1}` : ''}
          className={`${styles.NextButton} ${currentPage === totalPages ? styles.ButtonDisabled : ''} `}
        >
          <ArrowIcon />
        </Link>
      </div>
    </div>
  )
}
