export default function EditIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3912 16.0046C29.967 16.0046 29.5601 16.1732 29.2601 16.4731C28.9602 16.7731 28.7917 17.18 28.7917 17.6042V27.2014C28.7917 27.6256 28.6231 28.0325 28.3232 28.3324C28.0232 28.6324 27.6163 28.8009 27.1921 28.8009H4.79861C4.37439 28.8009 3.96754 28.6324 3.66757 28.3324C3.3676 28.0325 3.19907 27.6256 3.19907 27.2014V4.80788C3.19907 4.38366 3.3676 3.97681 3.66757 3.67684C3.96754 3.37687 4.37439 3.20835 4.79861 3.20835H14.3958C14.8201 3.20835 15.2269 3.03983 15.5269 2.73985C15.8268 2.43988 15.9954 2.03303 15.9954 1.60881C15.9954 1.18459 15.8268 0.777739 15.5269 0.477768C15.2269 0.177797 14.8201 0.00927466 14.3958 0.00927466H4.79861C3.52594 0.00927466 2.30539 0.514841 1.40548 1.41475C0.505566 2.31467 0 3.53521 0 4.80788V27.2014C0 28.4741 0.505566 29.6946 1.40548 30.5945C2.30539 31.4944 3.52594 32 4.79861 32H27.1921C28.4648 32 29.6853 31.4944 30.5852 30.5945C31.4852 29.6946 31.9907 28.4741 31.9907 27.2014V17.6042C31.9907 17.18 31.8222 16.7731 31.5222 16.4731C31.2223 16.1732 30.8154 16.0046 30.3912 16.0046ZM6.39815 17.2203V24.0023C6.39815 24.4265 6.56667 24.8334 6.86664 25.1334C7.16661 25.4333 7.57346 25.6019 7.99768 25.6019H14.7797C14.9902 25.6031 15.1989 25.5627 15.3938 25.4831C15.5887 25.4035 15.7659 25.2862 15.9154 25.138L26.9842 14.0532L31.5269 9.60649C31.6768 9.45779 31.7958 9.28088 31.877 9.08597C31.9582 8.89105 32 8.68198 32 8.47082C32 8.25966 31.9582 8.0506 31.877 7.85568C31.7958 7.66076 31.6768 7.48385 31.5269 7.33515L24.7448 0.47314C24.5961 0.323218 24.4192 0.204222 24.2243 0.123015C24.0294 0.0418091 23.8203 0 23.6092 0C23.398 0 23.1889 0.0418091 22.994 0.123015C22.7991 0.204222 22.6222 0.323218 22.4735 0.47314L17.9628 4.99983L6.86201 16.0846C6.71376 16.2341 6.59648 16.4113 6.51688 16.6062C6.43728 16.8011 6.39693 17.0098 6.39815 17.2203ZM23.6092 3.86416L28.1358 8.39084L25.8645 10.6622L21.3378 6.1355L23.6092 3.86416ZM9.59722 17.8761L19.0825 8.39084L23.6092 12.9175L14.1239 22.4028H9.59722V17.8761Z"
        fill="black"
      />
    </svg>
  )
}
