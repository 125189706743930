import Background from '../../components/elements/Background'
import Cards from '../../components/modules/Cards'
import CardStatistics from '../../components/modules/CardStatistics'
import Header from '../../components/modules/Header'
import { useCheckAuth } from '../../hooks/useCheckUser'

export default function CardsPage() {
  const isLoggedIn = useCheckAuth()

  return (
    <>
      {isLoggedIn && <Header />}
      <Background />
      <Cards />
      <CardStatistics />
    </>
  )
}
