import { useState } from 'react'
import styles from './ChangeMood.module.scss'
import { useUnit } from 'effector-react'
import { $user, setUser } from '../../../store/user'
import NothingIcon from '../../modules/ProfileSettings/icons/NothingIcon'
import { updateMood } from '../../../api/user'

const emoji = ['😡', '😤', '😏', '🙂‍↔️', '😳', '']

export default function ChangeMood() {
  const user = useUnit($user)
  const [selectedMood, setSelectedMood] = useState(user?.mood)

  const onUpdateMood = async (mood: string) => {
    if (user) {
      const { data } = await updateMood({
        url: '/user/updateMood',
        mood: mood,
        id: user.id,
      })
      setUser(data.user)
      setSelectedMood(mood)
    }
  }

  return (
    <div className={styles.ChangeMood}>
      {emoji.map(item => {
        return (
          <span
            className={`${styles.Emoji} ${selectedMood === item ? styles.SelectedEmoji : ''}`}
            onClick={() => onUpdateMood(item)}
          >
            {item || <NothingIcon />}
          </span>
        )
      })}
    </div>
  )
}
