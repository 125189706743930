import { createEffect } from 'effector'
import { toast } from 'react-toastify'
import api from './axiosClient'

export const getAllCards = createEffect(async ({ url }: { url: string }) => {
  const { data } = await api.get(url)

  if (data.message) {
    toast.warning(data.message)
    return { warningMessage: data.message }
  }

  return { data }
})

export const openCard = createEffect(
  async ({ url, code }: { url: string; code: string }) => {
    const { data } = await api.post(url, { code })

    return { data }
  },
)

export const changeCardStatus = createEffect(
  async ({ url, token }: { url: string; token: string }) => {
    const { data } = await api.post(url, { token })

    return { data }
  },
)

export const changeCardPrize = createEffect(
  async ({
    url,
    token,
    newPrize,
  }: {
    url: string
    token: string
    newPrize: string
  }) => {
    const { data } = await api.post(url, { token, newPrize })

    return { data }
  },
)

export const changeCardPrizes = createEffect(
  async ({
    url,
    token,
    dataPrizes,
  }: {
    url: string
    token: string
    dataPrizes: string
  }) => {
    const { data } = await api.post(url, { token, dataPrizes })

    return { data }
  },
)

export const createNewCard = createEffect(
  async ({ url, token }: { url: string; token: string }) => {
    const { data } = await api.post(url, { token })

    return { data }
  },
)

export const deleteCard = createEffect(
  async ({ url, token }: { url: string; token: string }) => {
    const { data } = await api.post(url, { token })

    return { data }
  },
)
