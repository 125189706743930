// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Background_Background__iSz8o {
  position: absolute;
  inset: 0px;
  z-index: -10;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
}`, "",{"version":3,"sources":["webpack://./src/components/elements/Background/Background.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAAA,UAAA;EAAA,YAAA;EAAA,YAAA;EAAA,WAAA;EAAA,sBAAA;EAAA,yBAAA;EAAA,4BAAA;EAEA;AAFA","sourcesContent":[".Background {\n  @apply inset-0 absolute -z-10 h-full w-full bg-cover bg-left bg-no-repeat;\n\n  background-image: url('../../../assets/images/background.webp');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Background": `Background_Background__iSz8o`
};
export default ___CSS_LOADER_EXPORT___;
