import React, { createContext, useContext, useState, ReactNode } from 'react'
import ImageModal from '../components/modules/modals/ImageModal'
import { ICard } from '../types/card'

interface ImageModalContextType {
  showImageModal: (content: ICard) => void
}

const ImageModalContext = createContext<ImageModalContextType | undefined>(
  undefined,
)

export const ImageModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [content, setContent] = useState<ICard | null>(null)

  const showImageModal = (msg: ICard) => {
    setContent(msg)
  }

  return (
    <ImageModalContext.Provider value={{ showImageModal }}>
      {children}
      {content && <ImageModal content={content} />}
    </ImageModalContext.Provider>
  )
}

export const useImageModal = () => {
  const context = useContext(ImageModalContext)
  if (!context) {
    throw new Error('useImageModal must be used within a ImageModalProvider')
  }
  return context
}
