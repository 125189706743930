// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_Card__pTtG- {
  position: relative;
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 127 80 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms
}
@media (hover: hover) {
  .Card_Card__pTtG-:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 215 0 / var(--tw-bg-opacity))
  }
}

.Card_OpeningCard__8rh08 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 215 0 / var(--tw-bg-opacity))
}
.Card_OpeningCard__8rh08 .Card_Tag__q8aHR {
  display: none
}

.Card_OpenCard__NRovH {
  background-color: transparent
}
.Card_OpenCard__NRovH .Card_Tag__q8aHR {
  display: none
}
.Card_OpenCard__NRovH:hover {
  background-color: inherit
}

.Card_Image__LshRk {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%
}`, "",{"version":3,"sources":["webpack://./src/components/modules/Card/Card.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAAA,YAAA;EAAA,WAAA;EAAA,eAAA;EAAA,iBAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,yDAAA;EAAA,kBAAA;EAAA,wDAAA;EAAA,wBAAA;EAAA,wDAAA;EAAA;AAAA;AAEA;EAEI;IAAA,kBAAA;IAAA;EAAA;AAEN;;AAIE;EAAA,kBAAA;EAAA;AAAA;AAGE;EAAA;AAAA;;AAKF;EAAA;AAAA;AAGE;EAAA;AAAA;AAIA;EAAA;AAAA;;AAKF;EAAA,kBAAA;EAAA,SAAA;EAAA,QAAA;EAAA,YAAA;EAAA;AAAA","sourcesContent":[".Card {\n  @apply relative h-[50px] w-[50px] cursor-pointer border border-solid border-white bg-coral transition-all duration-200;\n\n  @media (hover: hover) {\n    &:hover {\n      @apply bg-sponge;\n    }\n  }\n}\n\n.OpeningCard {\n  @apply bg-sponge;\n\n  .Tag {\n    @apply hidden;\n  }\n}\n\n.OpenCard {\n  @apply bg-transparent;\n\n  .Tag {\n    @apply hidden;\n  }\n\n  &:hover {\n    @apply bg-inherit;\n  }\n}\n\n.Image {\n  @apply absolute left-0 top-0 h-full w-full;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Card": `Card_Card__pTtG-`,
	"OpeningCard": `Card_OpeningCard__8rh08`,
	"Tag": `Card_Tag__q8aHR`,
	"OpenCard": `Card_OpenCard__NRovH`,
	"Image": `Card_Image__LshRk`
};
export default ___CSS_LOADER_EXPORT___;
