import { jwtDecode } from 'jwt-decode'
import { useState, useEffect } from 'react'

export const useCheckAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null)

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        const now = Date.now() / 1000

        if (decodedToken.exp === undefined || decodedToken.exp < now) {
          setIsLoggedIn(false)
        } else {
          setIsLoggedIn(true)
        }
      } catch (error) {
        console.error('Error decoding token:', error)
        setIsLoggedIn(false)
      }
    } else {
      setIsLoggedIn(false)
    }
  }, [])

  return isLoggedIn
}

export const useCheckAdmin = () => {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null)

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      try {
        const decodedToken: { exp?: number; permission: string } =
          jwtDecode(token)
        const now = Date.now() / 1000

        if (decodedToken.exp === undefined || decodedToken.exp < now) {
          setIsAdmin(false)
        } else if (decodedToken.permission === 'admin') {
          setIsAdmin(true)
        } else {
          setIsAdmin(false)
        }
      } catch (error) {
        console.error('Error decoding token:', error)
        setIsAdmin(false)
      }
    } else {
      setIsAdmin(false)
    }
  }, [])

  return isAdmin
}
