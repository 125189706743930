export default function LogoutIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4 0C4.70261 0 3.07475 0.674284 1.87452 1.87452C0.674284 3.07475 0 4.70261 0 6.4V11.2H3.2V6.4C3.2 5.55131 3.53714 4.73737 4.13726 4.13726C4.73737 3.53714 5.55131 3.2 6.4 3.2H25.6C26.4487 3.2 27.2626 3.53714 27.8627 4.13726C28.4629 4.73737 28.8 5.55131 28.8 6.4V25.6C28.8 26.4487 28.4629 27.2626 27.8627 27.8627C27.2626 28.4629 26.4487 28.8 25.6 28.8H6.4C5.55131 28.8 4.73737 28.4629 4.13726 27.8627C3.53714 27.2626 3.2 26.4487 3.2 25.6V20.8H0V25.6C0 27.2974 0.674284 28.9252 1.87452 30.1255C3.07475 31.3257 4.70261 32 6.4 32H25.6C27.2974 32 28.9252 31.3257 30.1255 30.1255C31.3257 28.9252 32 27.2974 32 25.6V6.4C32 4.70261 31.3257 3.07475 30.1255 1.87452C28.9252 0.674284 27.2974 0 25.6 0H6.4Z"
        fill="#dee4ee"
      />
      <path
        d="M1.6 14.4C1.17565 14.4 0.768687 14.5686 0.468629 14.8686C0.168571 15.1687 0 15.5757 0 16C0 16.4244 0.168571 16.8313 0.468629 17.1314C0.768687 17.4314 1.17565 17.6 1.6 17.6H16.9312L12.8752 21.6576C12.5837 21.9594 12.4225 22.3635 12.4261 22.7831C12.4298 23.2026 12.598 23.6039 12.8947 23.9005C13.1913 24.1972 13.5926 24.3654 14.0122 24.3691C14.4317 24.3727 14.8358 24.2115 15.1376 23.92L21.8512 17.2064C22.0241 17.0565 22.1627 16.8712 22.2577 16.6631C22.3527 16.4549 22.4019 16.2288 22.4019 16C22.4019 15.7712 22.3527 15.5451 22.2577 15.3369C22.1627 15.1288 22.0241 14.9435 21.8512 14.7936L15.136 8.08001C14.9884 7.9272 14.8119 7.80531 14.6166 7.72145C14.4214 7.6376 14.2115 7.59346 13.999 7.59161C13.7866 7.58977 13.5759 7.63025 13.3793 7.7107C13.1826 7.79115 13.004 7.90995 12.8538 8.06018C12.7035 8.21041 12.5847 8.38905 12.5043 8.58569C12.4238 8.78232 12.3834 8.99301 12.3852 9.20546C12.387 9.4179 12.4312 9.62785 12.515 9.82306C12.5989 10.0183 12.7208 10.1948 12.8736 10.3424L16.9312 14.4H1.6Z"
        fill="#dee4ee"
      />
    </svg>
  )
}
