import React, { createContext, useContext, useState, ReactNode } from 'react'
import MessageModal from '../components/modules/modals/MessageModal'

interface MessageModalContextType {
  showModal: (message: string) => void
}

const MessageModalContext = createContext<MessageModalContextType | undefined>(
  undefined,
)

export const MessageModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [message, setMessage] = useState<string | null>(null)

  const showModal = (msg: string) => {
    setMessage(msg)
  }

  return (
    <MessageModalContext.Provider value={{ showModal }}>
      {children}
      {message && <MessageModal message={message} />}
    </MessageModalContext.Provider>
  )
}

export const useMessageModal = () => {
  const context = useContext(MessageModalContext)
  if (!context) {
    throw new Error(
      'useMessageModal must be used within a MessageModalProvider',
    )
  }
  return context
}
